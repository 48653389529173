import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Aside from '../../components/Aside';
import Header from '../Header';

const useStyles = makeStyles((theme) => ({
  asideComponentSpace: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 210,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 270,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 380,
    },
  }
}));

const AppLayout = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <>
      <Header />
      <Aside />
      <Box pr={2} mt={3} className={classes.asideComponentSpace}>
        {children}
      </Box>
    </>
  );
};

export default AppLayout;
