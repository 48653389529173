import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Link, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';

import { getOrderById, submitWriterOrderReview } from '../../../api/Orders';
import { getUserId } from '../../../services/authService';
import { createCandidacyRequest } from '../../../api/CandidacyRequest';
import { createComment, getCommentsByOrderId } from '../../../api/Comment';
import { addOrderAttachment, getOrderAttachment, getOrderAttachmentsData } from "../../../api/OrderAttachments";
import { getUsers } from '../../../api/Users';
import { useFetch } from '../../../customHooks/useFetch';

import OrderCandidacyRequestDialog from './components/OrderCandidacyRequestDialog';
import OrderData from './components/OrderData';
import OrderActions from './components/OrderActions';
import SpinnerBackdrop from "../../../components/SpinnerBackdrop";
import Comments from '../../../components/Comments';
import AddOrderAttachment from "./components/AddOrderAttachment";
import OrderAttachments from "./components/OrderAttachments";
import Breadcrumb from "../../../components/Breadcrumb";
import { ORDER_STATUS_FINISHED, ORDER_STATUS_PROGRESS, ORDER_STATUS_VERIFIED } from '../../../enums/Order';
import CompletedOrderScreen from './components/CompletedOrderScreen';

const WriterOrder = () => {
  const { orderId } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showOrderCandidacyRequestDialog, setShowOrderCandidacyRequestDialog] = useState(false);
  const [showAddOrderAttachmentDialog, setShowAddOrderAttachmentDialog] = useState(false);

  const {
    data: order,
    loading: orderLoading,
    error: orderError,
    refetch: refetchOrder,
  } = useFetch(getOrderById, { variables: orderId });

  const isOrderFinished = [ORDER_STATUS_VERIFIED, ORDER_STATUS_FINISHED].includes(order?.status);

  const {
    data: attachments,
    loading: attachmentsLoading,
    error: attachmentsError,
    refetch: refetchAttachments,
  } = useFetch(getOrderAttachmentsData, { variables: orderId, skip: isOrderFinished });

  const isWritersOrder = order?.writerId === getUserId();

  const {
    data: comments = [],
    loading: loadingComments,
    refetch: refetchComments,
  } = useFetch(getCommentsByOrderId, { variables: orderId, skip: !isWritersOrder || isOrderFinished });

  const usersToFetch = [...new Set(comments?.map(({ authorId }) => authorId))];
  const usersToFetchParams = new URLSearchParams(usersToFetch.map((i) => ['ids', i]));
  const skipGetUsers = !usersToFetchParams.getAll('ids').length;
  const { data: users, refetch: refetchUsers } = useFetch(getUsers, {
    variables: { params: usersToFetchParams },
    skip: skipGetUsers || isOrderFinished,
  });

  useEffect(refetchComments, [!isWritersOrder]);
  useEffect(refetchUsers, [skipGetUsers, !loadingComments]);

  const commentsMapped = comments?.map((comment) => {
    const [user] = users?.filter(({ _id }) => _id === comment?.authorId) || [];
    return { ...comment, user, current: user?._id ===  getUserId() };
  });

  const handleSendComment = async (values) => {
    const comment = {
      ...values,
      orderId,
      authorId: getUserId(),
      createdAt: new Date(),
    };
    try {
      await createComment(comment);
      refetchComments();
      refetchUsers();
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const handleOrderCandidacyRequestDialog = () => {
    setShowOrderCandidacyRequestDialog((state) => !state);
  };

  const handleAddOrderAttachmentDialog = () => setShowAddOrderAttachmentDialog(!showAddOrderAttachmentDialog);

  const handleSubmitOrderCandidacyRequest = async ({ comment, file } = {}) => {
    setIsLoading(true);
    const candidacyRequestFormData = new FormData();
    candidacyRequestFormData.append('orderId', orderId);
    candidacyRequestFormData.append('writerId', getUserId());
    candidacyRequestFormData.append('comment', comment);
    if(file) {
      candidacyRequestFormData.append('file', file[0], file[0].name);
    }

    try {
      await createCandidacyRequest(candidacyRequestFormData);
      toast.success(t('toasts.candidacyRequestCreated'));
    } catch ({ response }) {
      toast.error(response.data);
    }
    setIsLoading(false);
    handleOrderCandidacyRequestDialog();
    refetchOrder();
  };

  const handleSubmitAddOrderAttachment = async ({ comment, file }) => {
    setIsLoading(true);
    const orderAttachmentFormData = new FormData();
    orderAttachmentFormData.append('comment', comment);
    orderAttachmentFormData.append('file', file[0], file[0].name);
    try {
      await addOrderAttachment(orderId, orderAttachmentFormData);
      toast.success(t('toasts.orderAttachmentCreated'));
    } catch ({ response }) {
      toast.error(response.data);
    }
    setIsLoading(false);
    handleAddOrderAttachmentDialog();
    refetchAttachments();
  };

  const getAttachmentUrl = async (id) => {
    try {
      const orderAttachment = await getOrderAttachment(id);
      if (orderAttachment) window.open(orderAttachment, '_blank');
    } catch ({ response }) {
      toast.error(response.data);
    }
  }

  const handleSubmit = async () => {
    if(order.status !== ORDER_STATUS_PROGRESS){
      return
    }
    setIsLoading(true);
    try {
      await submitWriterOrderReview(orderId );
      toast.success(t('toasts.orderUpdated'));
    } catch ({ response }) {
      toast.error(response.data);
    }
    setIsLoading(false);
    refetchOrder();
    refetchComments();
    refetchUsers();
  };

  const error = orderError || attachmentsError;
  const loading = orderLoading || attachmentsLoading;

  if (error && !loading) {
    toast.error(error.response.data);
  }

  if(loading) {
    return <SpinnerBackdrop open={loading} />
  }

  if(isOrderFinished){
    return <CompletedOrderScreen/> ;
  }

  return (
    <>
      <Breadcrumb>
        <Link
          to={isWritersOrder ? '/accepted-orders' : '/orders'}
          component={RouterLink}
          color="secondary"
        >
          {isWritersOrder ? t('navigation.acceptedOrders') : t('navigation.orders')}
        </Link>
        <Typography color="secondary">{order?.title}</Typography>
      </Breadcrumb>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs>
          <Typography variant="h2" gutterBottom>
            {order?.title}
          </Typography>
        </Grid>

        <Grid item xs="auto">
          {isWritersOrder ? (
            <Button color="secondary" variant="contained" onClick={handleAddOrderAttachmentDialog}>
              {t('orders.addAttachment')}
            </Button>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              onClick={handleOrderCandidacyRequestDialog}
            >
              {t('orders.candidacyRequest')}
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} md={8}>
          {order && <OrderData {...order} isWritersOrder={isWritersOrder} />}
          {isWritersOrder && order.status === ORDER_STATUS_PROGRESS && (
            <Box my={2}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs>
                  <OrderActions
                    onSubmit={handleSubmit}
                  />
                </Grid>
                {attachments && !!attachments.length && (
                  <OrderAttachments {...attachments} getAttachmentUrl={getAttachmentUrl} />
                )}
              </Grid>
            </Box>
          )}
        </Grid>
        {isWritersOrder && (
          <Grid item xs={6} md={4}>
            <Typography variant="h5" component={Box} pt={1.5} pb={2}>{t('common.chatWithCustomer')}</Typography>
            <Comments
              onSendMessage={handleSendComment}
              loading={loadingComments}
              comments={commentsMapped}
            />
          </Grid>
        )}
      </Grid>

      <OrderCandidacyRequestDialog
        open={showOrderCandidacyRequestDialog}
        onClose={handleOrderCandidacyRequestDialog}
        onSubmit={handleSubmitOrderCandidacyRequest}
        isLoading={isLoading}
      />
      <AddOrderAttachment
        open={showAddOrderAttachmentDialog}
        onClose={handleAddOrderAttachmentDialog}
        onSubmit={handleSubmitAddOrderAttachment}
        isLoading={isLoading}
      />
    </>
  );
};

export default WriterOrder;
