import React from 'react';

import { getRole } from '../../../services/authService';
import { AppCase, AppSwitch } from '../../../components/AppSwitchCase';
import { USER_ROLE_ADMIN, USER_ROLE_CUSTOMER, USER_ROLE_WRITER } from '../../../enums/User';
import AdminOrder from '../../admin/Order';
import CustomerOrder from '../../customer/Order';
import WriterOrder from '../../writer/Order';

const Order = () => {
  return (
    <AppSwitch test={getRole()}>
      <AppCase value={USER_ROLE_ADMIN}>
        <AdminOrder />
      </AppCase>
      <AppCase value={USER_ROLE_CUSTOMER}>
        <CustomerOrder />
      </AppCase>
      <AppCase value={USER_ROLE_WRITER}>
        <WriterOrder />
      </AppCase>
    </AppSwitch>
  )
};

export default Order;
