import { useEffect, useState, useCallback } from "react";

export const useFetch = (cb, { variables: payload, skip } = {}) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [refetchIndex, setRefetchIndex] = useState(0)

  const refetch = useCallback(
    () => {
      setLoading(true);
      setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);
    },
    [setRefetchIndex],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (skip) {
        setLoading(false);
        return
      }
      try {
        const response = await cb(payload);
        setData(response);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [refetchIndex]);

  return { data, error, loading, refetch };
};
