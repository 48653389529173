import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles({
  group: {
    flexDirection: 'row',
    '& .MuiTypography-root ': {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: 400,
      fontSize: '16px',
    }
  },
  title: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
  }
});

const CalculatorLanguage = ({ value, options, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={3} container justify="flex-end">
        <Typography className={classes.title} variant="body1" color="primary">
          {t('calculator.language')}
        </Typography>
      </Grid>

      <Grid item xs={9} container justify="flex-start">
        <RadioGroup value={value} onChange={onChange} className={classes.group}>
          {options?.map((item, i) => (
            <FormControlLabel
              value={item.value}
              control={<Radio color="primary" />}
              label={item.label}
              key={i}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default CalculatorLanguage;
