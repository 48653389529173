import 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const CompletedOrderScreen = () => {
  const { t } = useTranslation();
  return (
    <Paper>
      <Box p={4}>
        <Typography variant='subtitle1'>
          {t('writerCompletedScreen.message')}
        </Typography>
        <Box pb={3}/>
        <Typography variant='body1'>
          {t('writerCompletedScreen.footer')}
        </Typography>
      </Box>
    </Paper>
  );
};

export default CompletedOrderScreen;
