import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { omit } from "lodash";
import { getUserById, updateUserById } from "../../../api/Users";
import { useFetch } from "../../../customHooks/useFetch";
import { getUserId } from "../../../services/authService";
import SpinnerBackdrop from "../../../components/SpinnerBackdrop";
import Breadcrumb from "../../../components/Breadcrumb";

const CustomerSettings = () => {
  const { t } = useTranslation();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);
  const [isNewPasswordRepeatShown, setIsNewPasswordRepeatShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => setIsPasswordShown(!isPasswordShown);
  const handleClickShowNewPassword = () => setIsNewPasswordShown(!isNewPasswordShown);
  const handleClickShowNewPasswordRepeat = () => setIsNewPasswordRepeatShown(!isNewPasswordRepeatShown);
  const { register, handleSubmit, reset, errors, watch } = useForm();
  const userId = getUserId();
  const { data, loading, error } = useFetch(getUserById, { variables: userId });
  const watchNewPassword = watch('newPassword', '');

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const onUserUpdate = async (updatedUser) => {
    setIsLoading(true);
    let userInput = updatedUser;
    const { password, newPassword, newPasswordRepeat } = userInput;
    if (!password) {
      userInput = omit(updatedUser, 'password', 'newPassword', 'newPasswordRepeat');
    }

    if (password && newPassword && newPasswordRepeat) {
      const trimmedPasswords = ['password', 'newPassword', 'newPasswordRepeat'].reduce((acc, key) => {
        return { ...acc, [key]: userInput[key].trim() }
      }, {});
      userInput = { ...userInput, ...trimmedPasswords }
    }
    try {
      await updateUserById(userId, userInput);
      toast.success(t('toasts.userUpdated'));
      setIsLoading(false);
    } catch ({ response }) {
      toast.error(response.data);
      setIsLoading(false);
    }
  };

  const isCurrentlyLoading = loading || isLoading;

  if (error && !isCurrentlyLoading) {
    toast.error(error.response.data);
  }

  return (
    <>
      <Breadcrumb>
        <Typography color="secondary">{t('navigation.settings')}</Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={isCurrentlyLoading} />
      <Box my={2} pt={2} pb={2}>
        <Typography variant="h4">
          {t('settings.settings')}
        </Typography>
      </Box>
      <Paper component={Box} p={4}>
        <form id="settings" onSubmit={handleSubmit(onUserUpdate)}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">{t('settings.username')}</Typography>
              <TextField
                required
                variant="outlined"
                name="username"
                placeholder={t('settings.username')}
                inputRef={register({
                  required: true,
                })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">{t('settings.oldPassword')}</Typography>
              <TextField
                variant="outlined"
                name="password"
                placeholder={t('settings.oldPassword')}
                inputRef={register({
                  required: !!watchNewPassword ? t('validationErrors.formRequired') : false,
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors?.password}
                helperText={errors?.password?.message}
                type={isPasswordShown ? 'text' : 'password'}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">{t('settings.newPassword')}</Typography>
              <TextField
                variant="outlined"
                name="newPassword"
                placeholder={t('settings.newPassword')}
                inputRef={register({
                  minLength: {
                    value: 5,
                    message: t('validationErrors.invalidPasswordLength')
                  }
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowNewPassword}>
                        {isNewPasswordShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors?.newPassword}
                helperText={errors?.newPassword?.message}
                type={isNewPasswordShown ? 'text' : 'password'}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">{t('settings.newPasswordRepeat')}</Typography>
              <TextField
                variant="outlined"
                name="newPasswordRepeat"
                placeholder={t('settings.newPasswordRepeat')}
                inputRef={register({
                  validate: value => value === watchNewPassword || t('validationErrors.invalidNewPassword'),
                  required: watchNewPassword ? t('validationErrors.formRequired') : false,
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowNewPasswordRepeat}>
                        {isNewPasswordRepeatShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors?.newPasswordRepeat}
                helperText={errors?.newPasswordRepeat?.message}
                type={isNewPasswordRepeatShown ? 'text' : 'password'}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={3} />
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={12} md={3}>
              <Button fullWidth color="primary" variant="contained" type="submit" form="settings">
                {t('settings.save')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default CustomerSettings;
