import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Link, Box, Typography, Paper, makeStyles } from '@material-ui/core';
import MaterialTable, { MTableAction, MTableToolbar, MTableHeader } from 'material-table';
import { omit } from 'lodash';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import InformationDialog from '../../../components/InformationDialog';
import { getUsers, createUser, updateUserById, deleteUser } from '../../../api/Users';
import { useFetch } from '../../../customHooks/useFetch';
import UserRolesDropdown from './components/UserRolesDropdown';
import SpinnerBackdrop from '../../../components/SpinnerBackdrop';
import UserDataDialog from '../../../components/UserDataDialog';
import Breadcrumb from "../../../components/Breadcrumb";
import { ORDER_STATUS_FINISHED } from '../../../enums/Order';
import CreateUserModal from './components/CreateUserModal';
import EditUserModal from './components/EditUserModal';

const useStyles = makeStyles((theme) => ({
  searchFieldStyle: {
    marginTop: 90,
    minWidth: 400,
  },
  actions: {
    marginTop: 85,
  },
  tableHeader: {
    paddingBottom: 40,
    paddingRight: 0,
    paddingLeft: 0,
  },
  boldFontWeight: {
    fontWeight: 600,
  },
}));

const Users = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(undefined);
  const [userToDelete, setUserToDelete] = useState(undefined);
  const [isApprovingRemoveUserShow, setIsApprovingRemoveUserShow] = useState(false);
  const [isInformationDialogShow, setIsInformationDialogShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState('');
  const [userIdState, setUserIdState] = useState();
  const { data, loading, error, refetch } = useFetch(getUsers);

  const columns = [
    {
      title: t('users.username'),
      field: 'username',
      defaultSort: 'asc',
      render: ({ _id, username }) => (
        <Link href="#" onClick={() => setUserIdState(_id)} color='secondary' className={classes.boldFontWeight}>
          {username}
        </Link>
      ),
    },
    { title: t('users.email'), field: 'email' },
    { title: t('users.ordersCount'), field: 'orders', render: ({ orders }) => orders.length },
  ];

  const onUserCreate = async (userToCreate) => {
    setIsLoading(true);
    try {
      await createUser(userToCreate);
      toast.success(t('toasts.userCreated'));
      refetch();
      setIsLoading(false);
    } catch ({ response }) {
      toast.error(response.data);
      setIsLoading(false);
    }
    setIsCreateUserModalOpen(false);
  };
  const onUserUpdate = async (userUpdate) => {
    if (!userUpdate) {
      return;
    }
    setIsLoading(true);
    let object = userUpdate;
    if (!object.password) {
      object = omit(userUpdate, 'password');
    }
    const { _id: id } = userToUpdate;
    try {
      await updateUserById(id, object);
      toast.success(t('toasts.userUpdated'));
      refetch();
      setIsLoading(false);
    } catch ({ response }) {
      toast.error(response.data);
      setIsLoading(false);
    }
    setIsEditUserModalOpen(false);
    setUserToUpdate(undefined);
  };
  const onUserDelete = async () => {
    setIsLoading(true);
    const { _id: id } = userToDelete;
    try {
      await deleteUser(id);
      toast.success(t('toasts.userRemoved'));
      refetch();
      setIsLoading(false);
    } catch ({ response }) {
      toast.error(response.data);
      setIsLoading(false);
    }
    setIsApprovingRemoveUserShow(false);
    setUserToDelete(undefined);
  };

  const hasActiveOrders = (user) => {
    return !!user.orders?.find((order) => order.status !== ORDER_STATUS_FINISHED);
  };

  const onUserEditClick = (_, user) => {
    setIsEditUserModalOpen(true);
    setUserToUpdate(user);
  };
  const onUserDeleteClick = (_, user) => {
    if (hasActiveOrders(user)) {
      setIsInformationDialogShow(true);
    } else {
      setIsApprovingRemoveUserShow(true);
      setUserToDelete(user);
    }
  };
  const onEditUserModalClose = () => {
    setUserToUpdate(undefined);
    setIsEditUserModalOpen(false);
  };

  const onCreateUserModalClose = () => {
    setIsCreateUserModalOpen(false);
  };
  const onDeleteUserModalClose = () => setIsApprovingRemoveUserShow(false);

  const handleInformationDialog = () => setIsInformationDialogShow(false);

  const handleUserRoleChange = (event) => {
    setSelectedUserRole(event.target.value);
  };

  const filterUsersByRole = (role, data) => {
    if (data) return role ? data.filter((user) => user.role === role) : data;
  };

  if (error && !loading) {
    toast.error(error.response.data);
  }

  return (
    <>
      <Breadcrumb>
        <Typography color="secondary">{t('navigation.users')}</Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={loading} />
      <MaterialTable
        title={
          <Box display="flex" flexDirection='column'>
            <Box my={2}>
              <Typography variant='h4'>{t('users.title')}</Typography>
            </Box>
            <UserRolesDropdown onChange={handleUserRoleChange} value={selectedUserRole} />
          </Box>
        }
        columns={columns}
        data={filterUsersByRole(selectedUserRole, data)}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        localization={{
          header: { actions: t('orders.actions') },
          toolbar: {
            searchPlaceholder: t('tooltips.search'),
            searchTooltip: t('tooltips.search'),
          },
          pagination: {
            labelRowsSelect: t('common.rows'),
            firstTooltip: t('tooltips.firstPage'),
            previousTooltip: t('tooltips.previousPage'),
            nextTooltip: t('tooltips.nextPage'),
            lastTooltip: t('tooltips.lastPage'),
          },
        }}
        components={{
          Container: (props) => <Paper elevation={0} {...props} />,
          Toolbar: (props) => <MTableToolbar
            {...props}
            classes={{
              root: classes.tableHeader,
              searchField: classes.searchFieldStyle,
              actions: classes.actions,
            }}
          />,
          Header: (props) => <MTableHeader
            {...props}
            classes={{
              header: classes.boldFontWeight,
            }}
          />,
          Action: (props) => {
            const { render, ...restActionProps } = props.action;
            return render ? render(restActionProps) : <MTableAction {...props} />;
          },
        }}
        actions={[
          {
            render: (props) => (
              <Button {...props} variant="contained" color="primary" style={{ marginLeft: 10 }}>
                {t('users.button')}
              </Button>
            ),
            icon: '',
            onClick: () => setIsCreateUserModalOpen(true),
            position: 'toolbar',
          },
          {
            icon: 'edit',
            tooltip: t('tooltips.edit'),
            onClick: onUserEditClick,
          },
          {
            icon: 'delete',
            tooltip: t('tooltips.delete'),
            onClick: onUserDeleteClick,
          },
        ]}
      />
      {isCreateUserModalOpen && (
        <CreateUserModal
          open={isCreateUserModalOpen}
          onClose={onCreateUserModalClose}
          onUserCreate={onUserCreate}
          isLoading={isLoading}
        />
      )}
      {isEditUserModalOpen && (
        <EditUserModal
          user={userToUpdate}
          open={isEditUserModalOpen}
          onClose={onEditUserModalClose}
          onUserUpdate={onUserUpdate}
          isLoading={isLoading}
        />
      )}
      <ConfirmationDialog
        open={isApprovingRemoveUserShow}
        onClose={onDeleteUserModalClose}
        title={t('users.deleteUser')}
        onConfirm={onUserDelete}
        isLoading={isLoading}
      />
      <InformationDialog
        open={isInformationDialogShow}
        title={t('users.userHasActiveOrders')}
        onClose={handleInformationDialog}
      />
      {userIdState && (
        <UserDataDialog
          userId={userIdState}
          open={Boolean(userIdState)}
          onClose={() => setUserIdState()}
        />
      )}
    </>
  );
};

export default Users;
