import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Box, Badge, IconButton } from '@material-ui/core';
import { NotificationsNone as NotificationsNoneIcon } from '@material-ui/icons';
import { getNotifications, removeNotification } from '../../api/Notification';
import { useFetch } from '../../customHooks/useFetch';
import NotificationsList from './components/NotificationsList';

const Notifications = ({ className }) => {
  const [listAnchor, setListAnchor] = useState(null);
  const { data: notifications = [], refetch: refetchNotifications } = useFetch(getNotifications);
  const notificationsCount = notifications && notifications.length;
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(refetchNotifications, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleListAnchor = (e) => setListAnchor(e.currentTarget);

  const handleRemoveNotification = async (notificationId) => {
    if (!notificationId) return;
    try {
      await removeNotification(notificationId);
      toast.success(t('toasts.notificationRemoved'), { position: 'top-left' });
      refetchNotifications();
    } catch ({ response }) {
      toast.error(response);
    }
  };
  return (
    <Box className={className}>
      <IconButton onClick={handleListAnchor} color="inherit">
        <Badge badgeContent={notificationsCount} color="secondary">
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>

      <NotificationsList
        open={Boolean(listAnchor && notificationsCount)}
        anchorEl={listAnchor}
        notifications={notifications}
        onClose={() => setListAnchor(null)}
        onRemoveNotification={handleRemoveNotification}
      />
    </Box>
  );
};

export default Notifications;
