import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormLabel,
  FormHelperText,
  InputBase,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&.Mui-error': {
      borderColor: red[500],
    },
  },
}));

const UploadFileField = ({ name, disabled, error, helperText, ...restArgs }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const handleFile = (e) => setFile(e.target.value);
  return (
    <>
      <Button
        component={FormLabel}
        disabled={disabled}
        className={classes.root}
        variant="outlined"
        error={error}
        fullWidth
      >
        <InputBase
          type="file"
          name={name}
          onChange={handleFile}
          style={{ display: 'none' }}
          {...restArgs}
        />

        {!file && t('common.uploadFile')}

        {file && (
          <>
            <Typography variant="body1" noWrap>
              {t('common.selectedFile')}
              {/*todo check if this works on Windows os */}
              {`: ${file.substring(file.lastIndexOf('\\') + 1, file.length)}`}
            </Typography>
          </>
        )}
      </Button>

      {error && (
        <FormHelperText variant="outlined" error>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default UploadFileField;
