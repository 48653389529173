import React from 'react';

import { getRole } from '../../../services/authService';
import { AppCase, AppSwitch } from '../../../components/AppSwitchCase';
import { USER_ROLE_ADMIN, USER_ROLE_CUSTOMER, USER_ROLE_WRITER } from '../../../enums/User';
import AdminSettings from "../../admin/Settings";
import CustomerSettings from "../../customer/Settings";
import WriterSettings from "../../writer/Settings";

const Settings = () => {
  return (
    <AppSwitch test={getRole()}>
      <AppCase value={USER_ROLE_ADMIN}>
        <AdminSettings />
      </AppCase>
      <AppCase value={USER_ROLE_CUSTOMER}>
        <CustomerSettings />
      </AppCase>
      <AppCase value={USER_ROLE_WRITER}>
        <WriterSettings />
      </AppCase>
    </AppSwitch>
  )
};

export default Settings;
