import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Link,
  IconButton, makeStyles,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

const ITEM_HEIGHT = 80;

const useStyles = makeStyles((theme) => ({
  message: {
    whiteSpace: 'pre-wrap',
  }
}));

const NotificationsList = ({
                             open,
                             anchorEl,
                             onClose,
                             onRemoveNotification,
                             notifications = [],
                           }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          width: '40ch',
        },
      }}
    >
      {notifications?.map((notification, index) => (
        <Box key={notification?._id}>
          <ListItem>
            <ListItemText
              className={classes.message}
              primary={notification?.message}
              secondary={
                notification?.url && (
                  <Link
                    to={notification?.url}
                    color="secondary"
                    underline="always"
                    component={RouterLink}
                    onClick={onClose}
                  >
                    {t('common.viewDetails')}
                  </Link>
                )
              }
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => onRemoveNotification(notification?._id)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {!!notifications.length && index !== notifications.length-1 && <Divider />}
        </Box>
      ))}
    </Menu>
  );
};

export default NotificationsList;
