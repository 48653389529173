import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

const OrderFormSelect = ({
  name,
  label,
  rules,
  error,
  disabled,
  control,
  options,
  defaultValue,
  ...restArgs
}) => (
  <FormControl variant="outlined" fullWidth>
    <InputLabel>{label}</InputLabel>
    <Controller
      as={Select}
      control={control}
      name={name}
      label={label}
      rules={rules}
      disabled={disabled}
      defaultValue={defaultValue}
      {...restArgs}
    >
      {options?.map(({ _id, name }) => (
        <MenuItem key={_id} value={_id || ''}>
          {name}
        </MenuItem>
      ))}
    </Controller>
    <FormHelperText variant="outlined" error>
      {error?.message}
    </FormHelperText>
  </FormControl>
);

export default OrderFormSelect;
