import React from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import {Link, Typography} from '@material-ui/core';
import { createOrder } from '../../../api/Orders';
import { getUsers } from '../../../api/Users';
import { useFetch } from '../../../customHooks/useFetch';

import OrderForm from '../Order/components/OrderForm';
import { USER_ROLE_CUSTOMER } from '../../../enums/User';
import SpinnerBackdrop from "../../../components/SpinnerBackdrop";
import Breadcrumb from "../../../components/Breadcrumb";

const CreateOrder = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: users, loading, error } = useFetch(getUsers);
  const customers = users?.filter(({ role }) => [USER_ROLE_CUSTOMER].includes(role)) || [];

  const handleSubmit = async (values) => {
    const orderFormData = new FormData();
    Object.entries(values)
      .filter(([_, value]) => value)
      .forEach(([key, value]) => {
        if (value[0] instanceof File) {
          orderFormData.append('file', value[0], value[0].name);
        } else {
          orderFormData.append(key, value);
        }
      });
    try {
      await createOrder(orderFormData);
      history.push('/orders');
      toast.success(t('toasts.orderCreated'));
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  if (error && !loading) {
    toast.error(error.response.data);
  }

  return (
    <>
      <Breadcrumb>
        <Link to="/orders" component={RouterLink} color="secondary">
          {t('navigation.orders')}
        </Link>
        <Typography color="secondary">
          {t('orders.create')}
        </Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={loading} />
      <Typography variant="h2" gutterBottom>
        {t('orders.create')}
      </Typography>
      <OrderForm customers={customers} onSubmit={handleSubmit} />
    </>
  );
};

export default CreateOrder;
