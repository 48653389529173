import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Badge, makeStyles, Divider } from '@material-ui/core';
import { deepOrange, green } from '@material-ui/core/colors';

const useStyles = makeStyles({
  pending: {
    '& .MuiBadge-badge': { backgroundColor: deepOrange[500] },
  },
  complete: {
    '& .MuiBadge-badge': { backgroundColor: green[500] },
  },
});

const OrderWriterStatus = ({ writerId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box my={2}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs="auto">
          {writerId ? (
            <Badge variant="dot" className={classes.complete}>
              <Typography variant="button">{t('orders.writerAssigned')}</Typography>
            </Badge>
          ) : (
            <Badge variant="dot" className={classes.pending}>
              <Typography variant="button">{t('orders.lookingForWriter')}</Typography>
            </Badge>
          )}
        </Grid>
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderWriterStatus;
