import { toast } from "react-toastify";
import { clearAccessToken, getAccessToken } from '../authService';
import { INVALID_TOKEN } from "../../enums/Validators";
import history from '../../history.js';

export const tokenInterceptor = (config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers = { Authorization: `Bearer ${accessToken}` };
  }
  return config;
};

export const unauthorisedResponse = ((error) => {
  if (error.response?.status === 401 && error.response?.data === INVALID_TOKEN) {
    clearAccessToken();
    localStorage.setItem('prevPathState', history.location.pathname);
    history.push('/');
    toast.error('User session expired. Please login again.');
  }
  throw error;
});

export const dataExtractionInterceptor = ({ data }) => data;
