import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import MaterialTable, { MTableAction, MTableToolbar, MTableHeader } from 'material-table';
import { Link, Button, Box, Typography, Paper, makeStyles } from '@material-ui/core';

import { getOrders, deleteOrderById } from '../../../api/Orders';
import { useFetch } from '../../../customHooks/useFetch';

import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { getRole, isWriter, isAdmin, isCustomer } from '../../../services/authService';
import SpinnerBackdrop from "../../../components/SpinnerBackdrop";
import OrderStatusesDropdown from "./components/OrderStatusesDropdown";
import Price from '../../../components/Price';
import Breadcrumb from "../../../components/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  searchFieldStyle: {
    marginTop: 90,
    minWidth: 400,
  },
  actions: {
    marginTop: 85,
  },
  tableHeader: {
    paddingBottom: 40,
    paddingRight: 0,
    paddingLeft: 0,
  },
  boldFontWeight: {
    fontWeight: 600,
  },
}));

const Orders = () => {
  const classes = useStyles();
  const userRole = getRole();
  const [showApprovingRemoveOrder, setShowApprovingRemoveOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');

  const history = useHistory();
  const { t } = useTranslation();
  const { data: orders, loading, error, refetch } = useFetch(getOrders);
  const isOrderPriceVisible = isAdmin() || isCustomer();
  const isOrderCompensationVisible = isAdmin() || isWriter();

  const columns = [
    {
      title: t('orders.order'),
      field: 'title',
      defaultSort: 'asc',
      render: ({ _id, title }) => (
        <Link to={`/orders/${_id}`} component={RouterLink} color='secondary' className={classes.boldFontWeight}>
          {title}
        </Link>
      ),
    },
    {
      title: t('orders.status'),
      field: 'status',
      render: ({ status }) => (
        t(`orderStatuses.${status}`)
      ),
    },
    { title: t('orders.school'), field: 'school', hidden: isWriter() },
    { title: t('orders.category'), field: 'category' },
    { title: t('orders.numberOfDeliverables'), field: 'numberOfDeliverables' },
    { title: t('orders.deliverableType'), field: 'deliverableType',  render: ({ deliverableType }) => (
        t(`orderDeliverableTypeOptions.${deliverableType}`)
      ), },
    {
      title: t('orders.deadline'),
      field: 'deadline',
      render: ({ deadline }) => format(new Date(deadline), 'dd MMM yyyy'),
    },
    {
      title: t('orders.priceForTheOrder'),
      field: 'price',
      hidden: !isOrderPriceVisible,
      render: ({ price }) => <Price price={price} />,
    },
    {
      title: t('orders.compensationForTheOrder'),
      field: 'compensation',
      hidden: !isOrderCompensationVisible,
      render: ({ compensation }) => <Price price={compensation} />,
    },
  ];

  const handleCreateOrderClick = () => history.push('/orders/create');

  const handleDeleteOrderClick = (_, order) => {
    handleConfirmationDialog();
    setSelectedOrder(order);
  };

  const handleConfirmationDialog = () => {
    if (!showApprovingRemoveOrder) setSelectedOrder(undefined);
    setShowApprovingRemoveOrder(!showApprovingRemoveOrder);
  };

  const handleDeleteOrder = async () => {
    setIsLoading(true);
    try {
      await deleteOrderById(selectedOrder?._id);
      handleConfirmationDialog();
      toast.success(t('toasts.orderRemoved'));
      refetch();
    } catch ({ response }) {
      toast.error(response.data);
    }
    setIsLoading(false);
  };

  const handleOrderStatusChange = (event) => {
    setSelectedOrderStatus(event.target.value);
  };

  const filterOrdersByStatus = (status, orders) => {
    return status ? orders.filter(order => order.status === status) : orders;
  };

  if (error && !loading) {
    toast.error(error.response.data);
  }

  return (
    <>
      <Breadcrumb>
        <Typography color="secondary">{t('navigation.orders')}</Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={loading} />
      <MaterialTable
        title={
          <Box display="flex" flexDirection='column'>
            <Box my={2}>
              <Typography variant='h4' gutterBottom>{t('orders.title')}</Typography>
            </Box>
            <OrderStatusesDropdown onChange={handleOrderStatusChange} value={selectedOrderStatus} userRole={userRole} />
          </Box>
        }
        columns={columns}
        data={orders ? filterOrdersByStatus(selectedOrderStatus, orders) : undefined}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        localization={{
          header: { actions: isAdmin() && t('orders.actions') },
          body: { emptyDataSourceMessage: t('orders.noOrdersToDisplay') },
          toolbar: {
            searchPlaceholder: t('tooltips.search'),
            searchTooltip: t('tooltips.search'),
          },
          pagination: {
            labelRowsSelect: t('common.rows'),
            firstTooltip: t('tooltips.firstPage'),
            previousTooltip: t('tooltips.previousPage'),
            nextTooltip: t('tooltips.nextPage'),
            lastTooltip: t('tooltips.lastPage'),
          },
        }}
        components={{
          Container: (props) => <Paper elevation={0} {...props} />,
          Toolbar: (props) => <MTableToolbar
            {...props}
            classes={{
              root: classes.tableHeader,
              searchField: classes.searchFieldStyle,
              actions: classes.actions,
            }}
          />,
          Header: (props) => <MTableHeader
            {...props}
            classes={{
              header: classes.boldFontWeight,
            }}
          />,
          Action: (props) => {
            const { render, ...restActionProps } = props.action;
            return render ? render(restActionProps) : <MTableAction {...props} />;
          },
        }}
        actions={[
          {
            icon: '',
            position: 'toolbar',
            onClick: handleCreateOrderClick,
            render: (props) =>
              isAdmin() && (
                <Button {...props} variant="contained" color="primary" style={{ marginLeft: 10 }}>
                  {t('orders.create')}
                </Button>
              ),
          },
          {
            icon: 'delete',
            tooltip: t('tooltips.delete'),
            hidden: !isAdmin(),
            onClick: handleDeleteOrderClick,
          },
        ]}
      />

      <ConfirmationDialog
        open={showApprovingRemoveOrder}
        title={t('orders.deleteOrder', { order: selectedOrder?.title })}
        onClose={handleConfirmationDialog}
        onConfirm={handleDeleteOrder}
        isLoading={isLoading}
      />
    </>
  );
};

export default Orders;
