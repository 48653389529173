import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton, makeStyles, TextField } from '@material-ui/core';
import { Send } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: -theme.spacing(1),
    marginBottom: 'auto',
  },
}));

const CommentForm = ({ className, onSendMessage = () => {} }) => {
  const { handleSubmit, register, reset, errors } = useForm();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSendComment = () => {
    handleSubmit(onSendMessage)();
    reset();
  };
  const handleUserKeyPress = (e) => {
    const ctrlEnterCombination = e.key === 'Enter' && e.ctrlKey;
    const commandEnterCombination = e.key === 'Enter' && e.metaKey;
    if (ctrlEnterCombination || commandEnterCombination) handleSendComment();
  };
  return (
    <form id="comment-form" className={className} onSubmit={handleSubmit(handleSendComment)}>
      <TextField
        rows={5}
        fullWidth
        multiline
        name="message"
        variant="outlined"
        error={!!errors?.message}
        onKeyDown={handleUserKeyPress}
        helperText={errors?.message?.message}
        inputRef={register({ required: t('validationErrors.formRequired') })}
        InputProps={{
          autoComplete: 'off',
          placeholder: t('form.message'),
          endAdornment: (
            <IconButton type="submit" className={classes.button}>
              <Send />
            </IconButton>
          ),
        }}
      />
    </form>
  );
};

export default CommentForm;
