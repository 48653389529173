import React, { useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Tabs, Tab, Typography, Link, Paper, Grid, Box } from '@material-ui/core';

import { PaypalComponent } from './Paypal';
import { AppCase, AppSwitch } from '../../../components/AppSwitchCase';
import Breadcrumb from "../../../components/Breadcrumb";
import SpinnerBackdrop from '../../../components/SpinnerBackdrop';
import { useFetch } from '../../../customHooks/useFetch';
import { getOrderById } from '../../../api/Orders';
import {
  ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT,
  ORDER_STATUS_VERIFIED,
} from '../../../enums/Order';
import { ManualPayment } from './ManualPayment';

const OrderCheckout = () => {
  const { orderId } = useParams();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);
  const history = useHistory();
  const {
    data,
    loading: orderLoading,
    error: orderError,
  } = useFetch(getOrderById, { variables: orderId });
  const order = data || {};
  const { status, title, price } = order;
  const waitingForPayment = [ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT, ORDER_STATUS_VERIFIED].includes(status);

  // if we have a problem with loading order from our BD we don't allow user to pay for it
  if(orderError && !orderLoading) {
    toast.error(orderError.response.data);
    history.push('/orders');
  }

  if(status && !waitingForPayment) {
    toast.error('Order not available for payment');
    history.push('/orders');
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // todo: add styles, add translations;
  return (
    <Grid container spacing={3}>
      <Breadcrumb>
        <Link to="/orders" component={RouterLink} color="secondary">
          {t('navigation.orders')}
        </Link>
        <Link to="orders/:orderId" component={RouterLink} color="secondary">
          {title}
        </Link>
        <Typography color="secondary">
          {t('orderCheckout.payment')}
        </Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={orderLoading} />
      <Grid item xs={12}>
        <Paper>
          <Box p={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {t('orderCheckout.name')}
                </Typography>
                <Typography variant="body1">{title}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {t('orderCheckout.price')}
                </Typography>
                <Typography variant="body1">{price} EUR</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Paypal" />
          <Tab label={t('orderCheckout.manualPaymentTitle')} />
          {/*<Tab label="another payment system 3" />*/}
        </Tabs>
        <Box my={4} />
        <AppSwitch test={currentTab}>
          <AppCase value={0}>
            <PaypalComponent order={order} orderId={orderId} />
          </AppCase>
          <AppCase value={1}>
            <ManualPayment order={order}/>
          </AppCase>
          <AppCase value={2}>
            <h1>payment tab 3</h1>
          </AppCase>
        </AppSwitch>
      </Grid>
    </Grid>
  );
};

export default OrderCheckout;
