import instance from '../../services/axiosInstance';

export const getUsers = async (params) => {
  return instance.get('users', params)
};

export const getUserById = async (id) => {
  return instance.get(`users/${id}`)
};

export const getUserStatistic = async (id) => {
  return instance.get(`users/${id}/statistic`);
};

export const createUser = async (userToCreate) => {
  return instance.post('users', userToCreate)
};

export const updateUserById = async (id, updateInfo) => {
  return instance.put(`users/${id}`, updateInfo)
};

export const deleteUser =  async (id) => {
  return instance.delete(`users/${id}`)
};
