import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from "react-i18next";
import { ListItem, ListItemAvatar, ListItemText, IconButton, Typography, Box } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const OrderAttachments = (props) => {
  const { getAttachmentUrl, ...attachments } = props;
  const { t } = useTranslation();
  const data = Object.values(attachments);

  return (
    <>
      <Typography variant="h5" component={Box} py={2}>{t('common.attachedFiles')}</Typography>
      {data.map(
        ({comment, created_at, _id: id}) => {
          const createdAtStr = format(new Date(created_at), 'dd MMM yyyy hh:mm');
          return (<ListItem key={id}>
            <ListItemAvatar>
              <IconButton onClick={() => getAttachmentUrl(id)}>
                <GetAppIcon/>
              </IconButton>
            </ListItemAvatar>
            <ListItemText primary={comment} secondary={createdAtStr}/>
          </ListItem>)
        }
      )}
    </>
  );
};

export default OrderAttachments;
