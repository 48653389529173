import React, { memo } from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgb(255 255 255 / 40%)",
  },
}));

const SpinnerBackdrop = (props) => {
  const classes = useStyles();
  const { open } = props;

  return (
    <Backdrop className={classes.backdrop} invisible={true} open={open}>
      <CircularProgress size={120} />
    </Backdrop>
  );
}

export default memo(SpinnerBackdrop)
