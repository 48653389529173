import { createMuiTheme } from '@material-ui/core/styles';

export const primaryColor = '#303030';
export const secondaryColor = '#B19E93';
export const beige1Color = '#F3E7E0';
export const beige2Color = '#F8F1ED';
export const beige3Color = '#FAF5F2';
export const beige4Color = '#ECDED6';
export const beige5Color = '#FBF9F8';
export const beige6Color = '#A9968D';
export const beige7Color = '#9D897E';

export default createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    fontFamily: ['"Montserrat", sans-serif'],
    h1: { fontFamily: 'Lora' },
    h2: { fontFamily: 'Lora' },
    h3: { fontFamily: 'Lora' },
    h4: { fontFamily: 'Lora' },
    h5: { fontFamily: 'Lora' },
    h6: { fontFamily: 'Lora' },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiListItem: {
      button: {
        paddingTop: 20,
        paddingBottom: 20,
      },
    },
    MuiDialogActions: {
      root: {
        padding: '18px 24px',
      },
    },
    MuiAppBar: {
      root: {
        zIndex: 1300,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${beige4Color}`,
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: `1px solid ${beige4Color}`,
        backgroundColor: "white",
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: beige4Color,
      },
    },
    MuiListItemIcon: {
      root: {
        color: primaryColor,
        minWidth: '24px',
        paddingRight: 15
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `2px solid ${beige6Color}`,
        },
      },
    },
    MuiButton: {
      root: {
        minHeight: '45px',
      },
      contained: {
        borderRadius: '50px',
      },
    },
    MuiTable: {
      root: {
        backgroundColor: "white",
        border: '1px solid #E8E8E8',
        borderSpacing: 0,
        borderRadius: 8,
        borderCollapse: 'unset',
        paddingRight: 24,
        paddingLeft: 24,
        '&:not(:first-child)': {
          border: 'none',
          backgroundColor: beige5Color,
          padding: 0,
        },
      },
    },
    MuiTableRow: {
      root: {
        '&:last-of-type:not(:first-child) .MuiTableCell-root': {
          border: 'none',
        },
      },
    },
    MuiTablePagination: {
      root: {
        '&:last-child': {
          border: 'none'
        },
      },
      input: {
        position: "absolute",
      },
    },
    MuiPaper: {
      elevation0: {
        backgroundColor: beige5Color,
      },
    },
  },
});
