import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from "@material-ui/core";
import Decimal from 'decimal.js';


import { capturePaypalOrder, createPaypalOrder } from '../../../../api/Paypal';
import SpinnerBackdrop from '../../../../components/SpinnerBackdrop';
import {ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT} from "../../../../enums/Order";

export const PaypalComponent = (props) => {
  const { t } = useTranslation();
  const { order, orderId } = props;
  const { price, status } = order;
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const percentageToPay = (orderStatus) => (
    [ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT].includes(orderStatus) ? 0.2 : 0.8
  );

  const decimalPrice = new Decimal(Number(price));
  const decimalPercentages = new Decimal(percentageToPay(status));
  const priceToPay = decimalPrice.times(decimalPercentages).toDecimalPlaces(2, Decimal.ROUND_CEIL).toNumber();


  const paypalRef = useRef();
  useEffect(() => {
    if(!window.paypal?.Buttons) {
      history.push(`/orders/${orderId}`);
      toast.error('Payment is not available');
      return ;
    }
    const paypalButtons = window.paypal
      .Buttons({
          style: {
            layout: 'horizontal'
          },
          createOrder: () => {
            setLoading(true);
            const payload = { id: order._id };
            return createPaypalOrder(payload).then(data => {
              setLoading(false);
              return data.orderID;
            })
          },
          onApprove: async (data, actions) => {
            setLoading(true);
            return capturePaypalOrder(data.orderID, { id: order._id }).then(data => {
              setLoading(false);
              toast.success('Payment is successfully processed')
              history.push(`/orders/${order._id}`);
            })
          },
          onError: (err) => {
            setLoading(false);
            toast.error('Something gone wrong. Please contact us')
            console.error(err);
          },
        },
      );

    if(order._id) {
      paypalButtons.render(paypalRef.current);
    }
  }, [order]);
  return (<>
      <SpinnerBackdrop open={loading} />
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {t('orderCheckout.priceToPay')}
        </Typography>
        <Typography variant="body1">
          {priceToPay} EUR
        </Typography>
      </Grid>
      <Box mt={3} />
      <div ref={paypalRef} />
    </>
  )
};
