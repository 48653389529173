import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { useFetch } from '../../customHooks/useFetch';
import { getUserById, getUserStatistic } from '../../api/Users';
import { USER_ROLE_WRITER } from '../../enums/User';
import WriterStatistics from '../WritersStatistic';

const useStyles = makeStyles((theme) => ({
  close: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const UserDataDialog = ({ open, onClose, userId }) => {
  const { data: user = {} } = useFetch(getUserById, { variables: userId });
  const { t } = useTranslation();
  const dialogTitle = t(`userModal.${user?.role}Account`);
  const classes = useStyles();

  const availableStatistic = [USER_ROLE_WRITER].includes(user?.role);

  const { data, refetch: refetchStatistic } = useFetch(getUserStatistic, {
    variables: userId,
    skip: !availableStatistic,
  });

  const [userStatistic] = data || [];

  useEffect(refetchStatistic, [!!availableStatistic]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        {dialogTitle}

        <IconButton className={classes.close} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar alt={user?.username} src={user?.logo} />
          </Grid>
          <Grid item xs>
            <Typography variant="caption">{t('userModal.username')}</Typography>

            <Typography variant="subtitle1" gutterBottom>
              {user?.username}
            </Typography>

            <Typography variant="caption">{t('userModal.email')}</Typography>

            <Typography variant="subtitle1" gutterBottom>
              {user?.email}
            </Typography>
          </Grid>
        </Grid>
        <Box mb={2} />
        {availableStatistic && (
          <>
            <WriterStatistics statistic={userStatistic} />
            <Box mb={2} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserDataDialog;
