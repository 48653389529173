import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Container,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { login } from "../../../api/Login";
import { clearPrevPath, isLoggedIn, storeAccessToken } from "../../../services/authService";
import { beige7Color } from "../../../theme";
import { confirmEmail } from '../../../api/EmailConfirmation';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
  },
  logo: {
    marginBottom: theme.spacing(3),
  },
  imageContainer: {
    backgroundColor: '#F8F1ED',
    [theme.breakpoints.down('sm')]: {
      padding: 40,
    },
    [theme.breakpoints.up('md')]: {
      padding: '60px 80px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '100px 120px 50px',
    },
  },
  title: {
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
      lineHeight: '40px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
      lineHeight: '50px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 70,
      lineHeight: '80px',
    },
  },
  backgroundImage: {
    marginTop: 'auto',
    '& img': {
      width: '100%',
      maxWidth: '420px',
    },
  },
  formContainer: {
    height: '100%',
    maxWidth: 420,
    textAlign: 'center',
    margin: 'auto',
  },
  signInText: {
    width: 470,
    marginBottom: 40,
    fontSize: 40,
  },
  signInButton: {
    fontSize: 16,
    textTransform: 'initial',
  },
  linkStyle: {
    color: beige7Color,
    textDecoration: 'none',
  },
}));

const EmailConfirmation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { token } = useParams();

  const [isNewPasswordShown, setIsNewPasswordShown] = useState(false);
  const [isNewPasswordRepeatShown, setIsNewPasswordRepeatShown] = useState(false);
  const handleClickShowNewPassword = () => setIsNewPasswordShown(!isNewPasswordShown);
  const handleClickShowNewPasswordRepeat = () => setIsNewPasswordRepeatShown(!isNewPasswordRepeatShown);

  const { errors, register, handleSubmit, watch } = useForm();
  const watchNewPassword = watch('newPassword', '');

  const adminEmail = process.env.REACT_APP_SUPPORT_EMAIL || 'info@paper-black.de';

  if(isLoggedIn()) {
    history.push('/orders');
  }

  const onSubmit = async (form) => {
    const { newPassword } = form;
    try {
      const { loginToken } = await confirmEmail(token, {
        newPassword: newPassword.trim(),
      });
      storeAccessToken(loginToken);
      history.push('/orders');
    } catch (error) {
      debugger;
      toast.error(error);
    }
  };

  return (
    <Grid container className={classes.root}>
      <Grid container direction="column" item xs={5} className={classes.imageContainer}>
        <Grid item>
          <img alt="" className={classes.logo} src={`${process.env.PUBLIC_URL}/paperBlackLogo.svg`} />
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="h1" className={classes.title}>
            {t('login.title')}
          </Typography>
        </Grid>
        <Grid item className={classes.backgroundImage}>
          <img alt="" src={`${process.env.PUBLIC_URL}/backGroundLogo.svg`} />
        </Grid>
      </Grid>
      <Grid item xs={7}>
        <Grid container className={classes.formContainer} justify="center" direction="column">
          <Grid item>
            <Typography variant="h2" className={classes.signInText}>
              {t('settings.activateEmail')}
            </Typography>
            <Box mt={1} />
          </Grid>
          <Grid item>
            <form id="login" onSubmit={handleSubmit(onSubmit)}>

              <Grid item>
                <TextField
                  variant="outlined"
                  name="newPassword"
                  placeholder={t('settings.newPassword')}
                  inputRef={register({
                    required: t('validationErrors.formRequired'),
                    minLength: {
                      value: 5,
                      message: t('validationErrors.invalidPasswordLength')
                    }
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowNewPassword}>
                          {isNewPasswordShown ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors?.newPassword}
                  helperText={errors?.newPassword?.message}
                  type={isNewPasswordShown ? 'text' : 'password'}
                  fullWidth
                />
              </Grid>
              <Box mt={2} />

              <Grid item>
                <TextField
                  variant="outlined"
                  name="newPasswordRepeat"
                  placeholder={t('settings.newPasswordRepeat')}
                  inputRef={register({
                    validate: value => value === watchNewPassword || t('validationErrors.invalidNewPassword'),
                    required: watchNewPassword ? t('validationErrors.formRequired') : false,
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowNewPasswordRepeat}>
                          {isNewPasswordRepeatShown ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors?.newPasswordRepeat}
                  helperText={errors?.newPasswordRepeat?.message}
                  type={isNewPasswordRepeatShown ? 'text' : 'password'}
                  fullWidth
                />
              </Grid>
              <Box mt={2} />
            </form>
            <Box mt={1} />
          </Grid>
          <Grid item>
            <Box mt={4} />
            <Button
              type="submit"
              form="login"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.signInButton}
            >
              {t('settings.save')}
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  );
};

export default EmailConfirmation;
