import React from 'react';
import { TextField } from '@material-ui/core';

const OrderFormField = ({ name, label, error, defaultValue, InputProps, ...restArgs }) => (
  <TextField
    name={name}
    label={label}
    error={!!error}
    helperText={error?.message}
    defaultValue={defaultValue}
    InputProps={{ autoComplete: 'off', ...InputProps }}
    variant="outlined"
    fullWidth
    {...restArgs}
  />
);

export default OrderFormField;
