import React from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import AppLayout from './layouts/AppLayout';
import history from './history';
import ProtectedRoute from "./components/ProtectedRoute";

import Login from './scenes/allUsers/Login';
import Orders from "./scenes/allUsers/Orders";
import Order from "./scenes/allUsers/Order";
import Settings from "./scenes/allUsers/Settings";

import CreateOrder from './scenes/admin/CreateOrder';
import Users from "./scenes/admin/Users";

import AcceptedOrders from "./scenes/writer/AcceptedOrders";

import OrderCheckout from './scenes/customer/OrderCheckout';
import CandidacyRequests from "./scenes/allUsers/CandidacyRequests";
import Calculator from './scenes/allUsers/Calculator';
import EmailConfirmation from './scenes/allUsers/EmailConfirmation';

const Routes = () => (
  <Router history={history}>
    <Switch>
      <ProtectedRoute exact path="/users" permission="see-user-page">
        <AppLayout>
          <Users />
        </AppLayout>
      </ProtectedRoute>
      <ProtectedRoute exact path="/orders" permission="see-orders-page">
        <AppLayout>
          <Orders />
        </AppLayout>
      </ProtectedRoute>
      <ProtectedRoute exact path="/accepted-orders" permission="see-accepted-orders-page">
        <AppLayout>
          <AcceptedOrders />
        </AppLayout>
      </ProtectedRoute>
      <ProtectedRoute path="/orders/create" permission="see-create-order-page">
        <AppLayout>
          <CreateOrder />
        </AppLayout>
      </ProtectedRoute>
      <ProtectedRoute path="/orders/:orderId/checkout" permission="see-order-checkout-page">
        <AppLayout>
          <OrderCheckout/>
        </AppLayout>
      </ProtectedRoute>
      <ProtectedRoute path="/orders/:orderId" permission="see-order-page">
        <AppLayout>
          <Order />
        </AppLayout>
      </ProtectedRoute>
      <ProtectedRoute exact path="/settings" permission="see-settings-page">
        <AppLayout>
          <Settings />
        </AppLayout>
      </ProtectedRoute>
      <ProtectedRoute exact path="/candidacy-requests" permission="see-candidacy-requests-page">
        <AppLayout>
          <CandidacyRequests />
        </AppLayout>
      </ProtectedRoute>
      <Route exact path="/login" component={Login} />
      <Route exact path="/calculator" component={Calculator} />
      <Route exact path="/confirm-email/:token" component={EmailConfirmation} />
      <Route path="*"><Redirect to="/login" /></Route>
    </Switch>
  </Router>
);

export default Routes;
