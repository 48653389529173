import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from '@material-ui/core';

const OrderApproveDecline = ({ onApprove, onDecline }) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Button color="primary" variant="contained" onClick={onApprove}>
            {t('orders.approve')}
          </Button>
        </Grid>
        <Grid item>
          <Button color="secondary" variant="contained" onClick={onDecline}>
            {t('orders.decline')}
          </Button>
        </Grid>
        <Grid item xs={12} component={Box} my={2} />
      </Grid>
    </>
  );
};

export default OrderApproveDecline;
