import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { ORDER_STATUSES, ORDER_STATUSES_FOR_ADMIN, ORDER_STATUSES_FOR_WRITER_ORDERS_PAGE } from '../../../../../enums/Order';

const OrderStatusesDropdown = (props) => {
  const { onChange, value, userRole } = props;
  const { t } = useTranslation();
  const statuses = {
    admin: ORDER_STATUSES_FOR_ADMIN,
    customer: ORDER_STATUSES,
    writer: ORDER_STATUSES_FOR_WRITER_ORDERS_PAGE,
  };

  return (
    <FormControl style={{ width: 410 }}>
      <InputLabel>{t("orders.selectOrderStatus")}</InputLabel>
      <Select
        value={value || ""}
        onChange={onChange}
        label={t("orders.selectOrderStatus")}
      >
        <MenuItem>{t("orderStatuses.allOrders")}</MenuItem>
        {statuses[userRole || 'customer']?.map((status) => (
          <MenuItem key={status} value={status}>
            {t(`orderStatuses.${status}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrderStatusesDropdown;
