import React from 'react';
import { Button } from '@material-ui/core';

const OrderFormButton = ({ color = 'primary', children, ...restArgs }) => (
  <Button color={color} variant="contained" size="large" fullWidth  {...restArgs}>
    {children}
  </Button>
);

export default OrderFormButton;
