import React from "react";
import { AppBar, makeStyles, Toolbar, Box } from "@material-ui/core";
import Notifications from '../../components/Notifications';
import { isAdmin } from '../../services/authService';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    height: 70,
  },
  image: {
    height: 50,
  },
  notifications: {
    marginLeft: 'auto',
  },
  breadcrumb: {
    marginLeft: 30,
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <AppBar position="sticky" color="primary">
      <Toolbar className={classes.toolbar}>
        <img alt="" src={`${process.env.PUBLIC_URL}/paperBlackLogoWhite.svg`} className={classes.image} />
        <Box id="breadcrumb" className={classes.breadcrumb} />
        {isAdmin() && <Notifications className={classes.notifications} />}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
