import instance from '../../services/axiosInstance';

export const createCandidacyRequest = async (createCandidacyRequestData) => {
  return instance.post('candidacy-requests', createCandidacyRequestData);
};

export const getCandidacyRequests = async (writerId) => {
  return instance.get('candidacy-requests',{ params: { writerId } });
};

export const getCandidacyRequestsByOrderId = async (orderId) => {
  return instance.get(`candidacy-requests/${orderId}`);
};

export const getCandidacyAttachment = async (candidacyRequestId) => {
  return instance.get(`candidacy-requests/${candidacyRequestId}/attachment`);
};

export const deleteCandidacyRequest = async (candidacyRequestId) => {
  return instance.delete(`candidacy-requests/${candidacyRequestId}`);
};
