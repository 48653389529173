import React, { useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { Box, Grid, Link, Typography } from '@material-ui/core';

import { getOrderById, getOrderInvoice } from '../../../api/Orders';
import { getUsers } from '../../../api/Users';
import { getUserId } from '../../../services/authService';
import { createComment, getCommentsByOrderId } from '../../../api/Comment';

import {
  ORDER_DECLINED_BY_ADMIN,
  ORDER_STATUS_FINISHED,
  ORDER_STATUSES_FOR_CUSTOMER,
  ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT,
  ORDER_STATUS_VERIFIED
} from '../../../enums/Order';

import Comments from '../../../components/Comments';
import { getOrderAttachment, getOrderAttachmentsData } from '../../../api/OrderAttachments';
import { useFetch } from '../../../customHooks/useFetch';

import SpinnerBackdrop from '../../../components/SpinnerBackdrop';
import OrderData from './components/OrderData';
import OrderAttachments from './components/OrderAttachments';
import OrderWriterStatus from './components/OrderWriterStatus';
import Breadcrumb from "../../../components/Breadcrumb";
import Steppers from '../../../components/Steppers';

const CustomerOrder = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();

  const {
    data: order,
    loading: orderLoading,
    error: orderError,
  } = useFetch(getOrderById, { variables: orderId });

  const canGetAttachments = [ORDER_STATUS_FINISHED, ORDER_STATUS_VERIFIED].includes(order?.status);

  const {
    data: attachments,
    loading: attachmentsLoading,
    error: attachmentsError,
    refetch: refetchAttachments,
  } = useFetch(getOrderAttachmentsData, { variables: orderId, skip: !canGetAttachments });

  const availableComments =
    [order?.writerId, order?.customerId].includes(getUserId()) &&
    ![ORDER_DECLINED_BY_ADMIN].includes(order?.status);

  const {
    data: comments = [],
    loading: loadingComments,
    refetch: refetchComments,
  } = useFetch(getCommentsByOrderId, { variables: orderId, skip: !availableComments });

  const usersToFetch = [...new Set(comments?.map(({ authorId }) => authorId))];
  const usersToFetchParams = new URLSearchParams(usersToFetch.map((i) => ['ids', i]));
  const skipGetUsers = !usersToFetchParams.getAll('ids').length;
  const { data: users, refetch: refetchUsers } = useFetch(getUsers, {
    variables: { params: usersToFetchParams },
    skip: skipGetUsers,
  });

  useEffect(refetchComments, [!availableComments]);
  useEffect(refetchUsers, [!loadingComments && !skipGetUsers]);
  useEffect(refetchAttachments, [canGetAttachments]);

  const commentsMapped = comments?.map((comment) => {
    const [user] = users?.filter(({ _id }) => _id === comment?.authorId) || [];
    return { ...comment, user, current: user?._id ===  getUserId() };
  });

  const steps = ORDER_STATUSES_FOR_CUSTOMER.map((i) => t(`orderStatuses.${i}`));
  const activeStep = ORDER_STATUSES_FOR_CUSTOMER.findIndex((i) => order?.status === i);

  const handleSendComment = async (values) => {
    const comment = {
      ...values,
      orderId,
      authorId: getUserId(),
      createdAt: new Date(),
    };
    try {
      await createComment(comment);
      refetchComments();
      refetchUsers();
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const getAttachmentUrl = async (id) => {
    try {
      const orderAttachment = await getOrderAttachment(id);
      if (orderAttachment) window.open(orderAttachment, '_blank');
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const handleDownloadInvoice = async () => {
    try {
      const invoice = await getOrderInvoice(orderId);
      if (invoice)  window.open(invoice, '_blank');
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const error = orderError || attachmentsError;
  const loading = orderLoading || attachmentsLoading;

  const availableWriterStatus = !loading && ![ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT].includes(order?.status);

  if (error && !loading) {
    toast.error(error.response.data);
  }

  return (
    <>
      <Breadcrumb>
        <Link to="/orders" component={RouterLink} color="secondary">
          {t('navigation.orders')}
        </Link>
        <Typography color="secondary">{order?.title}</Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={loading} />

      <Steppers steps={steps} activeStep={activeStep} />

      <Typography variant="h4" component="h2" gutterBottom>
        {order?.title}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          {availableWriterStatus && <OrderWriterStatus writerId={order?.writerId} />}
          {order && <OrderData onDownloadInvoice={handleDownloadInvoice} {...order} />}
          {attachments && !!attachments.length && (
            <OrderAttachments {...attachments} getAttachmentUrl={getAttachmentUrl} />
          )}
        </Grid>
        {availableComments && (
          <Grid item xs={4}>
            <Typography variant="h5" component={Box} pt={1.5} pb={2}>{t('common.chatWithWriter')}</Typography>
            <Comments
              onSendMessage={handleSendComment}
              loading={loadingComments}
              comments={commentsMapped}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CustomerOrder;
