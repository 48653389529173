import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, List, makeStyles } from '@material-ui/core';

import Comment from './comments/Comment';
import CommentForm from './comments/CommentForm';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: theme.spacing(5),
    maxHeight: 'calc(70vh - 140px)',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}));

const Comments = ({ comments = [], loading, disabled, onSendMessage }) => {
  const messagesEndRef = useRef(null);
  const { t } = useTranslation();
  const classes = useStyles();

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  useEffect(scrollToBottom, [comments]);

  return (
    <>
      <List className={classes.root} disablePadding>
        {!loading && (
          <Comment alignItems="center" message={t('comment.greetings')} user={{ role: 'admin' }} />
        )}

        {comments?.map((comment, i) => <Comment {...comment} key={i} />)}

        {loading && <CircularProgress color="primary" size={20} />}

        <li ref={messagesEndRef}></li>
      </List>

      {!disabled && <CommentForm onSendMessage={onSendMessage} />}
    </>
  );
};

export default Comments;
