import { getRole } from '../authService';
import { USER_ROLE_ADMIN, USER_ROLE_CUSTOMER, USER_ROLE_WRITER } from '../../enums/User';

const actions = {
  'see-user-page': [USER_ROLE_ADMIN],
  'see-orders-page': [USER_ROLE_ADMIN, USER_ROLE_CUSTOMER, USER_ROLE_WRITER],
  'see-create-order-page': [USER_ROLE_ADMIN],
  'see-order-page': [USER_ROLE_ADMIN, USER_ROLE_CUSTOMER, USER_ROLE_WRITER],
  'see-order-checkout-page': [USER_ROLE_CUSTOMER],
  'see-accepted-orders-page': [USER_ROLE_WRITER],
  'see-settings-page': [USER_ROLE_ADMIN, USER_ROLE_CUSTOMER, USER_ROLE_WRITER],
  'see-candidacy-requests-page': [USER_ROLE_ADMIN, USER_ROLE_WRITER],
};

export const userCan = (action) => {
  const currentRole = getRole();
  return Boolean(actions[action]?.includes(currentRole));
};
