import 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';

const OrderActions = (props) => {
  const { onSubmit } = props;
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} md={6}>
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          {t('form.complete')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default OrderActions;
