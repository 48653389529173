import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Euro } from '@material-ui/icons';

const OrderApproveDialog = ({ open, onClose, onFormSubmit }) => {
  const { handleSubmit, register, errors } = useForm();
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Order approving</DialogTitle>
      <DialogContent>
        <form id="approve-form" onSubmit={handleSubmit(onFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                name="price"
                variant="outlined"
                error={!!errors?.price}
                label={t('form.price')}
                helperText={errors?.price?.message}
                inputRef={register({ required: t('validationErrors.formRequired') })}
                InputProps={{ min: 10, endAdornment: <Euro fontSize="small" /> }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                name="compensation"
                variant="outlined"
                error={!!errors?.compensation}
                label={t('form.compensation')}
                helperText={errors?.compensation?.message}
                inputRef={register({ required: t('validationErrors.formRequired') })}
                InputProps={{ min: 10, endAdornment: <Euro fontSize="small" /> }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="approve-form" color="primary" variant="contained">
          {t('orders.approve')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderApproveDialog;
