import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from "react-toastify";
import MaterialTable, { MTableToolbar, MTableHeader } from 'material-table';
import { Box, Link, Typography, makeStyles, Paper, IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useFetch } from '../../../customHooks/useFetch';
import SpinnerBackdrop from "../../../components/SpinnerBackdrop";
import CandidacyRequestStatusesDropdown from "./components/CandidacyRequestsDropdown";
import { getCandidacyRequests, getCandidacyAttachment } from "../../../api/CandidacyRequest";
import Breadcrumb from "../../../components/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  searchFieldStyle: {
    marginTop: 90,
    minWidth: 400,
  },
  actions: {
    marginTop: 85,
  },
  tableHeader: {
    paddingBottom: 40,
    paddingRight: 0,
    paddingLeft: 0,
  },
  boldFontWeight: {
    fontWeight: 600,
  },
}));

const AdminCandidacyRequests = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedCandidacyRequestStatus, setSelectedCandidacyRequestStatus] = useState('');
  const { data: candidacyRequests, loading, error } = useFetch(getCandidacyRequests);

  const handleGetFileUrl = async (id) => {
    try {
      const candidacyRequestAttachment = await getCandidacyAttachment(id);
      if (candidacyRequestAttachment) window.open(candidacyRequestAttachment, '_blank');
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const columns = [
    {
      title: t('orders.order'),
      field: 'order',
      defaultSort: 'asc',
      render: ({ orderId, order }) => {
        if (order) {
          return (
            <Link to={`/orders/${orderId}`} component={RouterLink} color='secondary' className={classes.boldFontWeight}>
              {order}
            </Link>
          )
        } else {
          return (
            <Typography>{t('orders.orderRemoved')}</Typography>
          )
        }
      }
    },
    {
      title: t('candidacyRequests.status'),
      field: 'status',
      render: ({ status }) => !!status && (
        t(`candidacyRequestStatuses.${status}`)
      ),
    },
    { title: t('candidacyRequests.comment'), field: 'comment' },
    {
      title: t('candidacyRequests.createdAt'),
      field: 'created_at',
      render: ({ created_at }) => format(new Date(created_at), 'dd MMM yyyy'),
    },
    {
      title: t('candidacyRequests.fileUrl'),
      field: 'fileUrl',
      render: ({ _id }) => (
        <IconButton onClick={() => handleGetFileUrl(_id)}>
          <GetAppIcon />
        </IconButton>
      )
    },
  ];

  const handleCandidacyRequestStatusChange = (event) => {
    setSelectedCandidacyRequestStatus(event.target.value);
  };

  const filterCandidacyRequestsByStatus = (status, candidacyRequests) => {
    return status ? candidacyRequests.filter(candidacyRequest => candidacyRequest.status === status) : candidacyRequests;
  };

  if (error && !loading) {
    toast.error(error.response.data);
  }

  return (
    <>
      <Breadcrumb>
        <Typography color="secondary">{t('navigation.candidacyRequests')}</Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={loading} />
      <MaterialTable
        title={
          <Box display="flex" flexDirection='column'>
            <Box my={2}>
              <Typography variant='h4'>{t('navigation.candidacyRequests')}</Typography>
            </Box>
            <CandidacyRequestStatusesDropdown
              onChange={handleCandidacyRequestStatusChange}
              value={selectedCandidacyRequestStatus}
            />
          </Box>
        }
        columns={columns}
        data={
          candidacyRequests ? filterCandidacyRequestsByStatus(selectedCandidacyRequestStatus, candidacyRequests) : undefined
        }
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
        localization={{
          body: { emptyDataSourceMessage: t('candidacyRequests.noCandidacyRequestsToDisplay') },
          toolbar: {
            searchPlaceholder: t('tooltips.search'),
            searchTooltip: t('tooltips.search'),
          },
          pagination: {
            labelRowsSelect: t('common.rows'),
            firstTooltip: t('tooltips.firstPage'),
            previousTooltip: t('tooltips.previousPage'),
            nextTooltip: t('tooltips.nextPage'),
            lastTooltip: t('tooltips.lastPage'),
          },
        }}
        components={{
          Container: (props) => <Paper elevation={0} {...props} />,
          Toolbar: (props) => <MTableToolbar
            {...props}
            classes={{
              root: classes.tableHeader,
              searchField: classes.searchFieldStyle,
              actions: classes.actions,
            }}
          />,
          Header: (props) => <MTableHeader
            {...props}
            classes={{
              header: classes.boldFontWeight,
            }}
          />,
        }}
      />
    </>
  );
};

export default AdminCandidacyRequests;
