/* Order statuses enums start */

export const ORDER_STATUS_SEARCH = 'search';

export const ORDER_STATUS_PROGRESS = 'progress';

export const ORDER_STATUS_REVIEW = 'review';

export const ORDER_STATUS_FINISHED = 'finished';

export const ORDER_STATUS_WAITING_FOR_APPROVAL = 'waiting_for_approval';

export const ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT = 'waiting_for_deposit_payment';

export const ORDER_STATUS_VERIFIED = 'verified';

export const ORDER_DECLINED_BY_ADMIN = 'declined_by_admin';

export const ORDER_DECLINED_BY_CUSTOMER = 'declined_by_customer';

export const ORDER_STATUSES = [
  ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT,
  ORDER_STATUS_SEARCH,
  ORDER_STATUS_PROGRESS,
  ORDER_STATUS_REVIEW,
  ORDER_STATUS_VERIFIED,
  ORDER_STATUS_FINISHED,
];

export const ORDER_STATUSES_FOR_WRITER = [
  ORDER_STATUS_PROGRESS,
  ORDER_STATUS_REVIEW,
  ORDER_STATUS_VERIFIED,
  ORDER_STATUS_FINISHED,
];

export const ORDER_STATUSES_FOR_WRITER_ORDERS_PAGE = [
  ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT,
  ORDER_STATUS_SEARCH,
];

export const ORDER_STATUSES_FOR_CUSTOMER = [
  ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT,
  ORDER_STATUS_SEARCH,
  ORDER_STATUS_PROGRESS,
  ORDER_STATUS_REVIEW,
  ORDER_STATUS_VERIFIED,
  ORDER_STATUS_FINISHED,
];

export const ORDER_STATUSES_FOR_ADMIN = [
  ORDER_STATUS_WAITING_FOR_APPROVAL,
  ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT,
  ORDER_STATUS_SEARCH,
  ORDER_STATUS_PROGRESS,
  ORDER_STATUS_REVIEW,
  ORDER_STATUS_VERIFIED,
  ORDER_STATUS_FINISHED,
  ORDER_DECLINED_BY_ADMIN,
  ORDER_DECLINED_BY_CUSTOMER,
];

/* Order statuses enums end */

/* Order model enums values start */

export const ORDER_QUOTE_APA_STYLE = 'apa_style';
export const ORDER_QUOTE_HARVARD_STYLE = 'harvard_style';
export const ORDER_QUOTE_GERMAN_STYLE = 'german_style';
export const ORDER_QUOTE_ANOTHER_STYLE = 'another_style';

export const ORDER_QUOTES_OPTIONS = [
  ORDER_QUOTE_APA_STYLE,
  ORDER_QUOTE_HARVARD_STYLE,
  ORDER_QUOTE_GERMAN_STYLE,
  ORDER_QUOTE_ANOTHER_STYLE
];

export const ORDER_DELIVERABLES_PAGES = 'pages';
export const ORDER_DELIVERABLES_WORDS = 'words';
export const ORDER_DELIVERABLES_CHARACTERS = 'characters';

export const ORDER_DELIVERABLES_OPTIONS = [
  ORDER_DELIVERABLES_PAGES,
  ORDER_DELIVERABLES_WORDS,
  ORDER_DELIVERABLES_CHARACTERS
];

/* Order model enums values end */
