import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  TextField,
  Button,
  Radio,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import SpinnerBackdrop from "../../../../../components/SpinnerBackdrop";

const CreateUserModal = (props) => {
  const { open, onClose, onUserCreate, isLoading } = props;
  const { t } = useTranslation();

  const defaultValues = { role: 'customer' };
  const { register, errors, handleSubmit } = useForm({ defaultValues });
  const handleFormSubmit = async (data) => {
    const { email } = data;
    await onUserCreate({
      ...data,
      email: email.trim(),
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <SpinnerBackdrop open={isLoading} />
      <Box p={3} width={500}>
        <DialogTitle>
          {t('userModal.newUser')}
        </DialogTitle>
        <DialogContent>
          <form id="user-form" onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>{t('userModal.email')}</Typography>
                <TextField
                  fullWidth
                  name="email"
                  error={errors?.email}
                  helperText={errors?.email?.message}
                  placeholder={t("userModal.email")}
                  inputRef={register({
                    required: t("validationErrors.formRequired"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t("validationErrors.invalidEmailFormat")
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t('userModal.username')}</Typography>
                <TextField
                  fullWidth
                  name="username"
                  error={errors?.username}
                  helperText={errors?.username?.message}
                  placeholder={t("userModal.username")}
                  inputRef={register({
                    required: t("validationErrors.formRequired"),
                  })}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl>
                  <FormLabel>{t("userModal.role")}</FormLabel>
                  <RadioGroup name="role" defaultValue={defaultValues.role}>
                    <FormControlLabel
                      name="role"
                      value="admin"
                      label={t("userModal.adminAccount")}
                      control={
                        <Radio
                          inputRef={register}
                        />
                      }
                    />
                    <FormControlLabel
                      name="role"
                      value="customer"
                      label={t("userModal.customerAccount")}
                      control={
                        <Radio
                          inputRef={register}
                        />
                      }
                    />
                    <FormControlLabel
                      name="role"
                      value="writer"
                      label={t("userModal.writerAccount")}
                      control={
                        <Radio
                          inputRef={register}
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} style={{ padding: '0px 16px' }}>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                form="user-form"
                variant="contained"
              >
                {t("userModal.save")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth onClick={onClose}>
                {t("userModal.cancel")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateUserModal;
