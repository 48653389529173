import instance from '../../services/axiosInstance';

export const createComment = async (createCommentInfo) => {
  return instance.post('comments', createCommentInfo);
};

export const deleteComment = async (id) => {
  return instance.delete(`comments/${id}`);
};

export const getCommentsByOrderId = async (id) => {
  return instance.get(`comments/${id}`);
};
