import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import CalculatorPagesWords from './components/CalculatorPagesWords';
import CalculatorPaperType from './components/CalculatorPaperType';
import CalculatorDeadline from './components/CalculatorDeadline';
import CalculatorLanguage from './components/CalculatorLanguage';
import Hidden from '@material-ui/core/Hidden';

const MIN_PAGES = 1;
const MAX_PAGES = 70;
const MIN_WORDS = 300;
const MAX_WORDS = 21000;
const MIN_CHARACTERS = 2000;
const MAX_CHARACTERS = 140000;
const ADDITIONAL_AMOUNT_PER_PAGE = 5;

const useStyles = makeStyles((theme) => ({
  calcContainer: {
    overflow: 'hidden',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'white',
  },
  calculator: {
    maxWidth: '860px',
    margin: 'auto',
  },
  price: {
    whiteSpace: 'nowrap',
  },
  priceTitle: {
    marginTop: '23px',
    fontSize: '16px',
  },
  priceTitleMobile: {
    marginTop: '17px',
    marginRight: '7px',
    fontSize: '16px',
  },

}));

const Calculator = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [type, setType] = useState(0);
  const [pages, setPages] = useState(MIN_PAGES);
  const [words, setWords] = useState(MIN_WORDS);
  const [characters, setCharacters] = useState(MIN_CHARACTERS);
  const [deadlineType, setDeadlineType] = useState(0);
  const [paperType, setPaperType] = useState(0);
  const [language, setLanguage] = useState('de');

  const types = [
    { label: t('calculator.pages'), value: 0 },
    { label: t('calculator.words'), value: 1 },
    { label: t('calculator.characters'), value: 2 },
  ];

  const paperTypesOptions = [
    { label: t('calculator.paperTypes.termPaper'), value: 70 },
    { label: t('calculator.paperTypes.bachelorThesis'), value: 75 },
    { label: t('calculator.paperTypes.masterThesis'), value: 80 },
  ];

  const deadlineOption = [
    { label: t('calculator.deadline.normal'), value: 1 },
    { label: t('calculator.deadline.fast'), value: 1.05 },
    { label: t('calculator.deadline.fastest'), value: 1.15 },
  ];

  const languagesOptions = [
    { label: 'Deutsch', value: 'de' },
    { label: 'Englisch', value: 'en' },
  ];

  const handleChangeType = ({ target: { value } = {} }) => setType(value);
  const handleChangePages = (_, v) => setPages(v);
  const handleChangeWords = ({ target: { value } = {} }) => setWords(value);
  const handleChangeCharacters = ({ target: { value } = {} }) => setCharacters(value);
  const handleChangePaperType = (value) => setPaperType(value);
  const handleChangeDeadline = (_, value) => setDeadlineType(value);
  const handleChangeLanguage = ({ target: { value } = {} }) => setLanguage(value);

  const { value: perPage } = paperTypesOptions[paperType || 0] || {};
  const { value: multiplier } = deadlineOption[deadlineType || 0] || {};
  const pricePerPage = language === 'de' ? perPage : perPage + ADDITIONAL_AMOUNT_PER_PAGE;
  const getPagesMultiplier = () => {
    if(type === 2) return characters / MIN_CHARACTERS;
    if(type === 1) return words / MIN_WORDS;
    return pages;
  };
  const price = pricePerPage * getPagesMultiplier() * multiplier;

  const priceFormatted = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(price);

  const wordsErrorMsg = t('validationErrors.calculatorWords', { min: MIN_WORDS, max: MAX_WORDS });
  const charactersErrorMsg = t('validationErrors.calculatorCharacters', { min: MIN_CHARACTERS, max: MAX_CHARACTERS });
  const wordsError = words > MAX_WORDS || words < MIN_WORDS ? wordsErrorMsg : undefined;
  const charactersError = characters > MAX_CHARACTERS || characters < MIN_CHARACTERS ? charactersErrorMsg : undefined;
  const pagesWordsErrors = { wordsError, charactersError };

  return (
    <Box className={classes.calcContainer}>
      <Box className={classes.calculator} m={0} p={0}>
        <Grid container>
          {/*<Grid item xs={12}>*/}
          {/*  <Typography variant="h3" align="center" component={Box} py={4} gutterBottom>*/}
          {/*    {t('calculator.header')}*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          <Grid item xs={12} md={10}>
            <CalculatorPagesWords
              type={type}
              types={types}
              pages={pages}
              words={words}
              characters={characters}
              minPages={MIN_PAGES}
              maxPages={MAX_PAGES}
              minWords={MIN_WORDS}
              maxWords={MAX_WORDS}
              minCharacters={MIN_CHARACTERS}
              maxCharacters={MAX_CHARACTERS}
              errors={pagesWordsErrors}
              onChangeType={handleChangeType}
              onChangePages={handleChangePages}
              onChangeWords={handleChangeWords}
              onChangeCharacters={handleChangeCharacters}
            />

            <Box my={2} />

            <CalculatorPaperType
              value={paperType}
              options={paperTypesOptions}
              onChange={handleChangePaperType}
            />

            <Box my={2} />

            <CalculatorDeadline
              value={deadlineType}
              options={deadlineOption}
              onChange={handleChangeDeadline}
            />

            <Box my={2} />

            <CalculatorLanguage
              value={language}
              options={languagesOptions}
              onChange={handleChangeLanguage}
            />
          </Grid>

          <Hidden smDown>
            <Grid item container direction="column" xs={2}>
              <Typography className={`${classes.price} ${classes.priceTitle}`} variant="h6" align="center" gutterBottom>
                {t('calculator.approximatePrice')}
              </Typography>

              <Typography className={classes.price} variant="h3" align="left" gutterBottom>
                ~ {priceFormatted}
              </Typography>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item container justify="center" xs={12}>
              <Grid item>
                <Typography className={`${classes.price} ${classes.priceTitleMobile}`} variant="h6" align="center"
                            gutterBottom>
                  {t('calculator.approximatePrice')}
                </Typography>

              </Grid>
              <Grid item>
                <Typography className={classes.price} variant="h3" align="left" gutterBottom>
                  ~ {priceFormatted}
                </Typography>
              </Grid>

            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </Box>
  );
};

export default Calculator;
