import instance from '../../services/axiosInstance';

export const createPaypalOrder = async (payload) => {
  return instance.post('paypal/order/create', payload);
};

export const capturePaypalOrder = async (id, payload) => {
  return instance.post(`paypal/order/${id}/capture`, payload);
};

export const getPaypalOrders = async (id) => {
  return instance.get(`paypal/order/${id}`);
};
