import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Controller } from 'react-hook-form';
import { FormHelperText } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

const OrderFormDatePicker = ({
  label,
  name,
  control,
  disabled,
  error,
  rules,
  minDate,
  defaultValue,
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Controller
      autoOk
      fullWidth
      disableToolbar
      variant="inline"
      format="dd.MM.yyyy"
      inputVariant="outlined"
      as={DatePicker}
      name={name}
      label={label}
      rules={rules}
      error={!!error}
      control={control}
      minDate={minDate}
      disabled={disabled}
      defaultValue={defaultValue}
    />
    <FormHelperText variant="outlined" error>
      {error?.message}
    </FormHelperText>
  </MuiPickersUtilsProvider>
);

export default OrderFormDatePicker;
