import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import {
  ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT,
  ORDER_STATUS_VERIFIED,
  ORDER_STATUS_REVIEW, ORDER_STATUS_FINISHED,
} from '../../../../../enums/Order';
import Price from '../../../../../components/Price';

const useStyles = makeStyles((theme) => ({
  textItem: {
    whiteSpace: 'pre-wrap',
  },
}));

const OrderData = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    _id,
    deadline,
    title,
    description,
    status,
    category,
    numberOfDeliverables,
    deliverableType,
    price,
    invoiceUrl,
    onDownloadInvoice,
    subjectModule,
    customerProgressUrl,
    quote,
  } = props;
  const deadlineStr = format(new Date(deadline), 'dd MMM yyyy');
  const isOrderWaitingForDepositPayment = [ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT].includes(status);
  const isOrderWaitingForFinalPayment = [ORDER_STATUS_VERIFIED].includes(status);
  const isOrderWaitingForPayment = isOrderWaitingForDepositPayment || isOrderWaitingForFinalPayment;
  const data = [
    { name: t('form.title'), value: title },
    { name: t('form.quote'), value: t(`orderQuoteOptions.${quote}`) },
    { name: t('form.description'), value: description },
    { name: t('form.status'), value: t(`orderStatuses.${status}`) },
    { name: t('form.subjectModule'), value: subjectModule },
    { name: t('form.deadline'), value: deadlineStr },
    { name: t('form.numberOfDeliverables'), value: numberOfDeliverables },
    { name: t('form.deliverableType'), value: t(`orderDeliverableTypeOptions.${deliverableType}`) },
    { name: t('form.category'), value: category },
    {
      name: t('orders.invoice'),
      children: invoiceUrl && (
        <Grid item xs={12}>
          <Button onClick={onDownloadInvoice}>
            {t('orders.downloadInvoice')}
            <GetAppIcon />
          </Button>
        </Grid>
      ),
    },
    {
      name: t('form.progressUrl'),
      children: customerProgressUrl && (
        <Button href={customerProgressUrl} variant="outlined" target="_blank">
          {t('form.openDocument')}
        </Button>
      ),
      hidden: [ORDER_STATUS_REVIEW, ORDER_STATUS_VERIFIED, ORDER_STATUS_FINISHED].includes(status),
    },
    {
      name: t('form.price'),
      children: price && (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Price price={price} />
          </Grid>
          {isOrderWaitingForPayment && (
            <Grid item>
              <Button
                component={Link}
                to={`/orders/${_id}/checkout`}
                color="primary"
                variant="contained"
              >
                {isOrderWaitingForDepositPayment ? t('orders.payDeposit') : t('orders.payFinal')}
              </Button>
            </Grid>
          )}
        </Grid>
      ),
    },
  ].filter(({ value, children }) => value || children);
  const preparedData = data.filter(item=> !item.hidden);
  return (
    <Grid container spacing={3}>
      {preparedData.map(({ name, value, children }, i) => (
        <Grid item xs={12} sm={6} key={i} className={classes.textItem}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {name}
          </Typography>
          {value && <Typography variant="body1">{value}</Typography>}
          {children}
        </Grid>
      ))}
    </Grid>
  );
};

export default OrderData;
