import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Slider, Typography, makeStyles } from '@material-ui/core';
import { beige7Color } from '../../../../../theme.js';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  slider: {
    display: 'block',
  },
  group: {
    backgroundColor: 'transparent',
  },
  groupItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
  },
  option: {
    color: beige7Color,
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
  }
});

const CalculatorDeadline = ({ value, options, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={6} sm={3} container justify="flex-end">
        <Typography className={classes.title} variant="body1" color="primary">
          {t('calculator.submissionDeadline')}
        </Typography>
      </Grid>
      <Hidden smUp>
        <Grid item xs={6} container justify="flex-start">
          <Typography variant="subtitle2" className={classes.option}>
            {options[value || 0]?.label}
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={7}>
        <Box px={{ xs: 3, sm: 0 }}>
          <Slider
            marks
            step={1}
            max={options.length - 1}
            onChange={onChange}
            className={classes.slider}
          />
        </Box>
      </Grid>
      <Hidden xsDown>
        <Grid item xs={2}>
          <Typography variant="subtitle2" className={classes.option}>
            {options[value || 0]?.label}
          </Typography>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default CalculatorDeadline;
