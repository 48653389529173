import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { ORDER_STATUSES_FOR_WRITER } from "../../../../../enums/Order";

const OrderStatusesDropdown = (props) => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  return (
    <FormControl style={{ width: 235 }}>
      <InputLabel>{t("orders.selectOrderStatus")}</InputLabel>
      <Select
        value={value || ""}
        onChange={onChange}
        label={t("orders.selectOrderStatus")}
      >
        <MenuItem>{t("orderStatuses.allOrders")}</MenuItem>
        {ORDER_STATUSES_FOR_WRITER.map((status) => (
          <MenuItem key={status} value={status}>
            {t(`orderStatuses.${status}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default OrderStatusesDropdown;
