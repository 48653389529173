import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import './App.css';
import Routes from './router';
import theme from './theme';
import commonEn from './lang/en/index.json';
import commonDe from './lang/de/index.json';
import StyledToastContainer from './components/StyledToastContainer';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: commonEn,
      },
      de: {
        translation: commonDe,
      },
    },
    lng: 'de',
    fallbackLng: 'en',
  });

const App = () => (
  <ThemeProvider theme={theme}>
    <Routes />
    <StyledToastContainer />
  </ThemeProvider>
);

export default App;
