import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@material-ui/core';

const OrderHeader = (props) => {
  const { title, onViewCandidacies, disabled, isWaitingForApproveOrder, handleAddOrderAttachmentDialog } = props;
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </Grid>

      {!isWaitingForApproveOrder && (
        <Grid item xs="auto">
          <Button
            color="primary"
            variant="contained"
            disabled={!disabled}
            onClick={onViewCandidacies}
          >
            {t('candidacyProposal.viewCandidacies')}
          </Button>
        </Grid>
      )}

      <Grid item xs="auto">
        <Box ml={1}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleAddOrderAttachmentDialog}
          >
            {t('orders.addAttachment')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderHeader;
