import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Button, Link } from '@material-ui/core';
import WritersStatistic from "../../../../../../../components/WritersStatistic";

const OrderCandidaciesDetails = (props) => {
  const { _id, disabled, created_at, comment, onGetFileUrl, onAcceptCandidacy, userStatistic } = props;
  const { t } = useTranslation();
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {t('candidacyProposal.dateApplied')}
        </Typography>
        <Typography variant="body1">{format(new Date(created_at), 'dd/MM HH:mm')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {t('candidacyProposal.suggestedStructure')}
        </Typography>
        <Typography variant="body1">
          <Link color="secondary" underline="always" component="button" onClick={() => onGetFileUrl(_id)}>
            {t('candidacyProposal.attachment')}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary">
          {t('candidacyProposal.comment')}
        </Typography>
        <Typography variant="body1">{comment}</Typography>
      </Grid>
      <Grid item xs={12}>
        <WritersStatistic statistic={userStatistic} />
      </Grid>
      <Grid item>
        {!disabled && (
          <Button variant="contained" color="primary" onClick={() => onAcceptCandidacy(_id)}>
            {t('candidacyProposal.accept')}
          </Button>
        )}
        <Box mb={2} />
      </Grid>
    </Grid>
  );
};

export default OrderCandidaciesDetails;
