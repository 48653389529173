import React from 'react';
import { FormControl, MenuItem, Select, makeStyles } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

const useStyles = makeStyles({
  select: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
    background: 'none',
    '&:before, &:after': {
      display: 'none',
    },
  },
});

const CalculatorPagesWordsSlider = ({ value, options, onChange, ...restProps }) => {
  const classes = useStyles();
  return (
    <FormControl>
      <Select
        color="primary"
        value={value}
        onChange={onChange}
        className={classes.select}
        IconComponent={ExpandMoreIcon}
        {...restProps}
      >
        {options?.map((item, i) => (
          <MenuItem value={item?.value} key={i}>
            {item?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CalculatorPagesWordsSlider;
