import React from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const StyledContainer = styled(ToastContainer).attrs({})`
  .Toastify__toast-container {}
  .Toastify__toast {
    background-color: green
  }
  .Toastify__toast--error {
    background-color: red
  }
  .Toastify__toast--warning {
    background-color: orange
  }
  .Toastify__toast--success {
    background-color: green
  }
  .Toastify__toast-body {}
  .Toastify__progress-bar {}
`;

const StyledToastContainer = () => (
  <StyledContainer className="mt-5" />
);

export default StyledToastContainer;