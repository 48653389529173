import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { IconButton, List, ListItemText, MenuItem, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

const OrderCandidaciesDialogList = (props) => {
  const { candidacies, onSelectProposal } = props;
  const { t } = useTranslation();
  return (
    <List>
      {candidacies?.map((candidacy, i) => (
        <MenuItem button key={i} onClick={() => onSelectProposal(candidacy)}>
          <ListItemText
            primary={candidacy.user?.username}
            secondary={format(new Date(candidacy.created_at), 'dd/MM HH:mm')}
          />
          <IconButton>
            <ChevronRight />
          </IconButton>
        </MenuItem>
      ))}
      {!candidacies.length && (
        <Typography variant="body1" gutterBottom>
          {t('candidacyProposal.noData')}
        </Typography>
      )}
    </List>
  );
};

export default OrderCandidaciesDialogList;
