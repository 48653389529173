import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import { toast } from 'react-toastify';

import { getOrderById, getOrderInvoice, updateOrderById, acceptOrder, approveOrderById } from '../../../api/Orders';
import { getCandidacyRequestsByOrderId, getCandidacyAttachment } from '../../../api/CandidacyRequest';
import { getUsers } from '../../../api/Users';
import { createComment, getCommentsByOrderId } from '../../../api/Comment';
import { getPaypalOrders } from "../../../api/Paypal";
import {
  addOrderAttachment,
  getOrderAttachment,
  getOrderAttachmentsData,
  deleteOrderAtachment
} from "../../../api/OrderAttachments";
import { useFetch } from '../../../customHooks/useFetch';
import { USER_ROLE_CUSTOMER } from '../../../enums/User';
import {
  ORDER_STATUS_SEARCH,
  ORDER_STATUS_PROGRESS,
  ORDER_STATUS_WAITING_FOR_APPROVAL,
  ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT,
  ORDER_DECLINED_BY_ADMIN,
} from '../../../enums/Order';

import OrderForm from './components/OrderForm';
import OrderCandidaciesRequestsDialog from './components/OrderCandidaciesDialog';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import UserDataDialog from '../../../components/UserDataDialog';
import SpinnerBackdrop from '../../../components/SpinnerBackdrop';
import { getUserId } from '../../../services/authService';
import Comments from '../../../components/Comments';
import OrderAttachments from "./components/OrderAttachments";
import OrderApproveDialog from './components/OrderApproveDialog';
import OrderApproveDecline from './components/OrderApproveDecline';
import OrderHeader from './components/OrderHeader';
import OrderCustomerWriter from './components/OrderCustomerWriter';
import Breadcrumb from "../../../components/Breadcrumb";
import { CANDIDACY_REQUEST_STATUS_ACCEPTED } from "../../../enums/CandidacyRequest";
import AddOrderAttachment from "./components/AddOrderAttachment";

const AdminOrder = () => {
  const { orderId } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteInvoice, setIsDeleteInvoice] = useState(false);
  const [showOrderCandidaciesRequestsDialog, setShowOrderCandidaciesRequestsDialog] = useState(false);
  const [showAddOrderAttachmentDialog, setShowAddOrderAttachmentDialog] = useState(false);

  const {
    data: order,
    loading: orderLoading,
    error: orderError,
    refetch: refetchOrder,
  } = useFetch(getOrderById, { variables: orderId });

  const {
    data: candidacyRequests,
    loading: candidacyRequestLoading,
    error: candidacyRequestError,
    refetch: refetchCandidacyRequests,
  } = useFetch(getCandidacyRequestsByOrderId, { variables: orderId });

  const {
    data: comments = [],
    refetch: refetchComments,
    loading: loadingComments,
  } = useFetch(getCommentsByOrderId, { variables: orderId });

  const {
    data: attachments,
    loading: attachmentsLoading,
    error: attachmentsError,
    refetch: refetchOrderAttachments,
  } = useFetch(getOrderAttachmentsData, { variables: orderId });

  const { data: users, loading: usersLoading, error: usersError, refetch: refetchUsers } = useFetch(
    getUsers
  );

  const {
    data: payments,
    loading: paymentsLoading,
    error: paymentsError,
  } = useFetch(getPaypalOrders, { variables: orderId });

  let orderPayment = 0;
  const orderPaymentsArray = payments?.map((payment) => {
    const { value } = payment.payment.purchase_units[0].payments.captures[0].amount;
    return orderPayment += Number(value);
  });

  useEffect(() => {
    refetchOrder();
    refetchCandidacyRequests();
    refetchComments();
    refetchOrderAttachments();
    refetchUsers();
  }, [orderId]);

  const commentsMapped = comments?.map((comment) => {
    const [user] = users?.filter(({ _id }) => _id === comment?.authorId) || [];
    return { ...comment, user, current: user?._id === getUserId() };
  });

  const [isDisabled, setIsDisabled] = useState(!!orderId);
  const [candidacyAcceptLoading, setCandidacyAcceptLoading] = useState(false);
  const [showOrderApproveDialog, setShowOrderApproveDialog] = useState(false);
  const [showOrderDeclineDialog, setShowOrderDeclineDialog] = useState(false);
  const [deleteOrderAttachmentDialogState, setDeleteOrderAttachmentDialogState] = useState();
  const [userIdState, setUserIdState] = useState();

  const customers = users?.filter(({ role }) => USER_ROLE_CUSTOMER === role) || [];
  const [customer] = users?.filter(({ _id }) => _id === order?.customerId) || [];
  const [writer] = users?.filter(({ _id }) => _id === order?.writerId) || [];

  const availableCandidaciesRequests = !!candidacyRequests?.length;
  const isWaitingForApproveOrder = order?.status === ORDER_STATUS_WAITING_FOR_APPROVAL;

  const handleAddOrderAttachmentDialog = () => setShowAddOrderAttachmentDialog(!showAddOrderAttachmentDialog);

  const handleSendComment = async (values) => {
    const comment = {
      ...values,
      orderId,
      authorId: getUserId(),
      createdAt: new Date(),
    };
    try {
      await createComment(comment);
      refetchComments();
      refetchUsers();
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const handleCustomerData = () => setUserIdState(customer?._id);
  const handleWriterData = () => setUserIdState(writer?._id);

  const handleOrderCandidaciesRequestsDialog = () => {
    setShowOrderCandidaciesRequestsDialog((state) => !state);
  };

  const handleAcceptCandidacy = async (id) => {
    setCandidacyAcceptLoading(true);
    if([ORDER_STATUS_WAITING_FOR_APPROVAL, ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT].includes(order.status)) {
      setCandidacyAcceptLoading(false);
      handleOrderCandidaciesRequestsDialog();
      // todo add translation to german when
      toast.error('Writer can\'t be applied with current order status');
      return
    }
    try {
      await acceptOrder(orderId, {
        ...order,
        candidacyRequestId: id,
        orderStatus: ORDER_STATUS_PROGRESS,
        candidacyRequestStatus: CANDIDACY_REQUEST_STATUS_ACCEPTED,
      });
      toast.success(t('toasts.candidacyRequestAccepted'));
    } catch ({ response }) {
      toast.error(response.data);
    }

    setCandidacyAcceptLoading(false);
    handleOrderCandidaciesRequestsDialog();
    refetchOrder();
    refetchComments();
  };

  const handleGetFileUrl = async (id) => {
    try {
      const candidacyRequestAttachment = await getCandidacyAttachment(id);
      if(candidacyRequestAttachment) window.open(candidacyRequestAttachment, '_blank');
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const orderFormData = new FormData();
    Object.entries(values)
      .filter(([_, value]) => value)
      .forEach(([key, value]) => {
        if(value[0] instanceof File) {
          orderFormData.append('file', value[0], value[0].name);
        } else if (key === 'invoiceUrl') {
          orderFormData.append(key, order.invoiceUrl);
        } else {
          orderFormData.append(key, value);
        }

        if(isDeleteInvoice) {
          orderFormData.set('file', '');
          orderFormData.set('invoiceUrl', '');
        }
      });
    if(ORDER_STATUS_SEARCH === values.status && values.writerId) {
      orderFormData.set('writerId', null);
    }
    try {
      await updateOrderById(orderId, orderFormData);
      toast.success(t('toasts.orderUpdated'));
    } catch ({ response }) {
      toast.error(response.data);
    }
    setIsDeleteInvoice(false)
    setIsLoading(false);
    setIsDisabled(true);
    refetchOrder();
    refetchComments();
  };

  const handleEdit = () => {
    setIsDisabled(!isDisabled);
    setIsDeleteInvoice(false);
  };

  const getAttachmentUrl = async (id) => {
    try {
      const orderAttachment = await getOrderAttachment(id);
      if(orderAttachment) window.open(orderAttachment, '_blank');
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const handleApproveOrder = async (values) => {
    setIsLoading(true);
    try {
      await approveOrderById(orderId, {
        status: ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT,
        price: parseFloat(values.price),
        compensation: parseFloat(values.compensation),
      });
      toast.success(t('toasts.orderApproved'));
    } catch ({ response }) {
      toast.error(response.data);
    }
    setIsLoading(false);
    setShowOrderApproveDialog(false);
    refetchOrder();
  };

  const handleDeclineOrder = async () => {
    setIsLoading(true);
    try {
      await updateOrderById(orderId, { ...order, status: ORDER_DECLINED_BY_ADMIN });
      toast.success(t('toasts.orderDeclined'));
    } catch ({ response }) {
      toast.error(response.data);
    }
    setIsLoading(false);
    setShowOrderDeclineDialog(false);
    refetchOrder();
  };

  const handleDownloadInvoice = async () => {
    try {
      const invoice = await getOrderInvoice(orderId);
      if(invoice) window.open(invoice, '_blank');
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const onDeleteInvoice = () => {
    setIsDeleteInvoice(true);
  }

  const handleDeleteAttachment = async (id) => {
    try {
      await deleteOrderAtachment(deleteOrderAttachmentDialogState);
      refetchOrderAttachments();
      toast.success(t('toasts.orderAttachmentDeleted'));
    } catch ({ response }) {
      toast.error(response.data);
    }
    setDeleteOrderAttachmentDialogState(undefined);
  };

  const error = orderError || usersError || candidacyRequestError || attachmentsError || paymentsError;
  const loading = orderLoading || usersLoading || candidacyRequestLoading || attachmentsLoading || paymentsLoading;

  const handleSubmitAddOrderAttachment = async ({ comment, file }) => {
    setIsLoading(true);
    const orderAttachmentFormData = new FormData();
    orderAttachmentFormData.append('comment', comment);
    orderAttachmentFormData.append('file', file[0], file[0].name);
    try {
      await addOrderAttachment(orderId, orderAttachmentFormData);
      toast.success(t('toasts.orderAttachmentCreated'));
    } catch ({ response }) {
      toast.error(response.data);
    }
    setIsLoading(false);
    handleAddOrderAttachmentDialog();
    refetchOrderAttachments();
  };

  if(loading) {
    return <SpinnerBackdrop open={loading} />;
  }

  if(error && !loading) {
    toast.error(error.response.data);
  }

  return (
    <>
      <Breadcrumb>
        <Link to="/orders" component={RouterLink} color="secondary">
          {t('navigation.orders')}
        </Link>
        <Typography color="secondary">{order?.title}</Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={isLoading} />

      <OrderHeader
        title={order?.title}
        disabled={availableCandidaciesRequests}
        isWaitingForApproveOrder={isWaitingForApproveOrder}
        onViewCandidacies={handleOrderCandidaciesRequestsDialog}
        handleAddOrderAttachmentDialog={handleAddOrderAttachmentDialog}
      />

      {isWaitingForApproveOrder && (
        <OrderApproveDecline
          onApprove={() => setShowOrderApproveDialog(true)}
          onDecline={() => setShowOrderDeclineDialog(true)}
        />
      )}

      <OrderCustomerWriter
        customer={customer}
        writer={writer}
        onCustomerData={handleCustomerData}
        onWriterData={handleWriterData}
        orderPayment={orderPayment}
      />

      <Box mt={2} />

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <OrderForm
            onDownloadInvoice={handleDownloadInvoice}
            onDeleteInvoice={onDeleteInvoice}
            isDeleteInvoice={isDeleteInvoice}
            onSubmit={handleSubmit}
            onEdit={handleEdit}
            hasOrder={!!orderId}
            disabled={isDisabled}
            customers={customers}
            values={order}
          />
          {attachments && !!attachments.length && (
            <OrderAttachments
              {...attachments}
              getAttachmentUrl={getAttachmentUrl}
              onDeleteAttachment={(id) => setDeleteOrderAttachmentDialogState(id)}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5" component={Box} pt={1.5} pb={2}>
            {t('common.chat')}
          </Typography>
          <Comments
            onSendMessage={handleSendComment}
            loading={loadingComments}
            comments={commentsMapped}
          />
        </Grid>
      </Grid>

      <OrderCandidaciesRequestsDialog
        open={showOrderCandidaciesRequestsDialog}
        onClose={handleOrderCandidaciesRequestsDialog}
        candidacies={candidacyRequests}
        onAcceptCandidacy={handleAcceptCandidacy}
        onGetFileUrl={handleGetFileUrl}
        loading={candidacyAcceptLoading}
        selectedWriterId={order?.writerId}
      />

      {userIdState && (
        <UserDataDialog
          userId={userIdState}
          open={Boolean(userIdState)}
          onClose={() => setUserIdState()}
        />
      )}

      <ConfirmationDialog
        open={showOrderDeclineDialog}
        onClose={() => setShowOrderDeclineDialog(false)}
        title={t('orders.declineOrder')}
        onConfirm={handleDeclineOrder}
      />

      <ConfirmationDialog
        open={!!deleteOrderAttachmentDialogState}
        onClose={() => setDeleteOrderAttachmentDialogState(undefined)}
        title={t('orders.deleteOrderAttachmentConfirmation')}
        onConfirm={handleDeleteAttachment}
      />

      <OrderApproveDialog
        open={showOrderApproveDialog}
        onClose={() => setShowOrderApproveDialog(false)}
        onFormSubmit={handleApproveOrder}
      />
      <AddOrderAttachment
        open={showAddOrderAttachmentDialog}
        onClose={handleAddOrderAttachmentDialog}
        onSubmit={handleSubmitAddOrderAttachment}
        isLoading={isLoading}
      />
    </>
  );
};

export default AdminOrder;
