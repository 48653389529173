import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { userRoles } from '../../../../../enums/User';

const UserRolesDropdown = (props) => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  return (
    <FormControl style={{ width: 410 }}>
      <InputLabel>{t("users.selectUserRole")}</InputLabel>
      <Select
        value={value || ""}
        onChange={onChange}
        label={t("users.selectUserRole")}
      >
        <MenuItem>{t("users.allUsers")}</MenuItem>
        {userRoles.map((role) => (
          <MenuItem key={role} value={role}>
            {t(`users.${role}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UserRolesDropdown;
