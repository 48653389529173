import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Typography, makeStyles } from '@material-ui/core';
import Price from '../../../../../components/Price';

const useStyles = makeStyles((theme) => ({
  textItem: {
    whiteSpace: 'pre-wrap',
  },
}));

const OrderData = ({
  title,
  description,
  deadline,
  category,
  numberOfDeliverables,
  deliverableType,
  writerProgressUrl,
  compensation,
  subjectModule,
  isWritersOrder,
  quote,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const deadlineStr = format(new Date(deadline), 'dd MMM yyyy');
  const data = [
    { name: t('form.title'), value: title },
    { name: t('form.quote'), value: t(`orderQuoteOptions.${quote}`) },
    { name: t('form.description'), value: description },
    { name: t('form.deadline'), value: deadlineStr },
    { name: t('form.numberOfDeliverables'), value: numberOfDeliverables },
    { name: t('form.deliverableType'), value: t(`orderDeliverableTypeOptions.${deliverableType}`) },
    { name: t('form.category'), value: category },
    { name: t('form.subjectModule'), value: subjectModule },
    {
      name: t('form.progressUrl'),
      children: writerProgressUrl && isWritersOrder && (
        <Button href={writerProgressUrl} variant="outlined" target="_blank">
          {t('form.openDocument')}
        </Button>
      ),
    },
    { name: t('form.compensation'), children: compensation && <Price price={compensation} /> },
  ].filter(({ value, children }) => value || children);

  return (
    <Grid container spacing={3}>
      {data.map(({ name, value, children }, i) => (
        <Grid item xs={12} sm={6} key={i} className={classes.textItem}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {name}
          </Typography>
          {value && <Typography variant="body1">{value}</Typography>}
          {children}
        </Grid>
      ))}
    </Grid>
  );
};

export default OrderData;
