import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, Typography } from '@material-ui/core';

const OrderCustomerWriter = (props) => {
  const { customer, writer, onCustomerData, onWriterData, orderPayment } = props;
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" spacing={2}>
      {customer && (
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>{t('common.customer')}</Grid>
            <Grid item>
              <Link
                onClick={onCustomerData}
                variant="body2"
                color="secondary"
                underline="always"
                component="button"
              >
                {customer?.username}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      )}

      {writer && (
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>{t('common.writer')}</Grid>
            <Grid item>
              <Link
                onClick={onWriterData}
                variant="body2"
                color="secondary"
                underline="always"
                component="button"
              >
                {writer?.username}
              </Link>
            </Grid>
          </Grid>
        </Grid>
      )}

      {!!orderPayment && (
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>{t('common.payment')}</Grid>
            <Grid item>
              <Typography variant='subtitle2'>
                {orderPayment}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OrderCustomerWriter;
