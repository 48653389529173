import React from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { isLoggedIn } from '../../services/authService';
import { userCan } from '../../services/ACL';

const ProtectedRoute = (props) => {
  const {permission, children, ...routeParams } = props;
  const history = useHistory();

  if (!isLoggedIn()) {
   localStorage.setItem('prevPathState', history.location.pathname);
    return <Route><Redirect to="/login" /></Route>;
  }
  return (
    <Route
      {...routeParams}
      render={
        () => (
          userCan(permission)
            ? children
            : <Redirect to="/" />
        )
      }
    />
  );
};

export default ProtectedRoute;
