import React from 'react';

const Price = ({ price }) => {
  const priceFormatted = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(price);
  return <>{price && priceFormatted}</>;
};

export default Price;
