import React from 'react';
import { Stepper, Step, StepLabel, StepConnector, makeStyles, withStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import { primaryColor, beige1Color } from '../../theme.js';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: theme.spacing(2),
  },
}));

const QontoConnector = withStyles({
  active: {
    '& $line': {
      borderColor: primaryColor,
    },
  },
  completed: {
    '& $line': {
      borderColor: primaryColor,
    },
  },
  line: {
    borderColor: beige1Color,
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: beige1Color,
    height: 22,
  },
  active: {
    color: primaryColor,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    position: 'relative',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1s infinite ease-in-out',
      backgroundColor: 'currentColor',
      content: '""',
    },
  },
  completed: {
    color: primaryColor,
    zIndex: 1,
    fontSize: 18,
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2)',
      opacity: 0,
    },
  },
});

const QontoStepIcon = (props) => {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div className={clsx(classes.root, { [classes.active]: active })}>
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

const Steppers = ({ steps = [], activeStep }) => {
  const classes = useStyles();
  return (
    <>
      {!!steps.length && (
        <Stepper activeStep={activeStep} className={classes.root} connector={<QontoConnector />}>
          {steps?.map((item) => (
            <Step key={item}>
              <StepLabel StepIconComponent={QontoStepIcon}>{item}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </>
  );
};

export default Steppers;
