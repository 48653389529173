import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Box,
  Grid,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SpinnerBackdrop from "../SpinnerBackdrop";

const ConfirmationDialog = (props) => {
  const { t } = useTranslation();
  const { title, open, onClose, onConfirm, isDisabled, isLoading } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <SpinnerBackdrop open={isLoading} />
      <Box p={3} width={500}>
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={isDisabled} onClick={onConfirm} variant="contained" fullWidth>
                {t('common.yes')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={onClose} fullWidth>
                {t('common.no')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  )
};

export default ConfirmationDialog;
