import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from "react-toastify";
import MaterialTable, { MTableToolbar, MTableHeader } from 'material-table';
import { Box, Link, Typography, makeStyles, Paper } from '@material-ui/core';

import { getOrders } from '../../../api/Orders';
import { useFetch } from '../../../customHooks/useFetch';
import { getUserId } from "../../../services/authService";
import SpinnerBackdrop from "../../../components/SpinnerBackdrop";
import OrderStatusesDropdown from "./components/OrderStatusesDropdown";
import Breadcrumb from "../../../components/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  searchFieldStyle: {
    marginTop: 90,
    minWidth: 400,
  },
  actions: {
    marginTop: 85,
  },
  tableHeader: {
    paddingBottom: 40,
    paddingRight: 0,
    paddingLeft: 0,
  },
  boldFontWeight: {
    fontWeight: 600,
  },
}));

const AcceptedOrders = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const writerId = getUserId();
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
  const { data: orders, loading, error } = useFetch(getOrders, { variables: writerId });

  const columns = [
    {
      title: t('orders.order'),
      field: 'title',
      defaultSort: 'asc',
      render: ({ _id, title }) => (
        <Link to={`/orders/${_id}`} component={RouterLink} color='secondary' className={classes.boldFontWeight}>
          {title}
        </Link>
      ),
    },
    {
      title: t('orders.status'),
      field: 'status',
      render: ({ status }) => (
        t(`orderStatuses.${status}`)
      ),
    },
    { title: t('orders.category'), field: 'category' },
    { title: t('orders.numberOfDeliverables'), field: 'numberOfDeliverables' },
    {
      title: t('orders.deadline'),
      field: 'deadline',
      render: ({ deadline }) => format(new Date(deadline), 'dd MMM yyyy'),
    },
  ];

  const handleOrderStatusChange = (event) => {
    setSelectedOrderStatus(event.target.value);
  };

  const filterOrdersByStatus = (status, orders) => {
    return status ? orders.filter(order => order.status === status) : orders;
  };

  if (error && !loading) {
    toast.error(error.response.data);
  }

  return (
    <>
      <Breadcrumb>
        <Typography color="secondary">{t('navigation.acceptedOrders')}</Typography>
      </Breadcrumb>
      <SpinnerBackdrop open={loading} />
      <MaterialTable
        title={
          <Box display="flex" flexDirection='column'>
            <Box my={2}>
              <Typography variant='h4'>{t('navigation.acceptedOrders')}</Typography>
            </Box>
            <OrderStatusesDropdown onChange={handleOrderStatusChange} value={selectedOrderStatus} />
          </Box>
        }
        columns={columns}
        data={orders ? filterOrdersByStatus(selectedOrderStatus, orders) : undefined}
        localization={{
          body: { emptyDataSourceMessage: t('orders.noOrdersToDisplay') },
          toolbar: {
            searchPlaceholder: t('tooltips.search'),
            searchTooltip: t('tooltips.search'),
          },
          pagination: {
            labelRowsSelect: t('common.rows'),
            firstTooltip: t('tooltips.firstPage'),
            previousTooltip: t('tooltips.previousPage'),
            nextTooltip: t('tooltips.nextPage'),
            lastTooltip: t('tooltips.lastPage'),
          },
        }}
        options={{ pageSize: 10 }}
        components={{
          Container: (props) => <Paper elevation={0} {...props} />,
          Toolbar: (props) => <MTableToolbar
            {...props}
            classes={{
              root: classes.tableHeader,
              searchField: classes.searchFieldStyle,
              actions: classes.actions,
            }}
          />,
          Header: (props) => <MTableHeader
            {...props}
            classes={{
              header: classes.boldFontWeight,
            }}
          />,
        }}
      />
    </>
  );
};

export default AcceptedOrders;
