import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, TextField, Slider, makeStyles } from '@material-ui/core';
import CalculatorPagesWordsSelect from './components/CalculatorPagesWordsSelect';
import { beige7Color } from '../../../../../theme.js';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  slider: { display: 'block' },
  error: {
    position: 'absolute'
  },
  option: {
    color: beige7Color,
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
  }
});

const CalculatorPagesWords = ({
                                type,
                                types,
                                pages,
                                words,
                                characters,
                                errors,
                                minPages,
                                maxPages,
                                minWords,
                                maxWords,
                                minCharacters,
                                maxCharacters,
                                onChangeType,
                                onChangePages,
                                onChangeWords,
                                onChangeCharacters,
                              }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={6} sm={3} container justify="flex-end">
        <CalculatorPagesWordsSelect value={type} options={types} onChange={onChangeType} />
      </Grid>
      <Hidden smUp>
        <Grid item xs={6} container justify="flex-start">
          <Typography variant="subtitle2" className={classes.option}>
            {type === 0 && t('calculator.pagesCount', { pages })}
            {type === 1 && t('calculator.wordsCount', { words })}
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={7}>
        <Box px={{ xs:3, sm:0 }}>
          {type === 0 && (
            <Slider
              step={1}
              min={minPages}
              max={maxPages}
              onChange={onChangePages}
              className={classes.slider}
            />
          )}
          {type === 1 && (
            <TextField
              fullWidth
              type="number"
              value={words}
              onChange={onChangeWords}
              error={!!errors.wordsError}
              helperText={<Typography variant="caption" className={classes.error}>{errors.wordsError}</Typography>}
              inputProps={{ min: minWords, max: maxWords, step: minWords }}
            />
          )}
          {type === 2 && (
            <TextField
              fullWidth
              type="number"
              value={characters}
              onChange={onChangeCharacters}
              error={!!errors.charactersError}
              helperText={<Typography variant="caption" className={classes.error}>{errors.charactersError}</Typography>}
              inputProps={{ min: minCharacters, max: maxCharacters, step: minCharacters }}
            />
          )}
        </Box>
      </Grid>

      <Hidden xsDown>
        <Grid item xs={2}>
          <Typography variant="subtitle2" className={classes.option}>
            {type === 0 && t('calculator.pagesCount', { pages })}
            {type === 1 && t('calculator.wordsCount', { words })}
          </Typography>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default CalculatorPagesWords;
