import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { candidacyRequestStatuses } from "../../../../../enums/CandidacyRequest";

const CandidacyRequestStatusesDropdown = (props) => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  return (
    <FormControl style={{ width: 410 }}>
      <InputLabel>{t("candidacyRequests.selectCandidacyRequestStatus")}</InputLabel>
      <Select
        value={value || ""}
        onChange={onChange}
        label={t("candidacyRequests.selectCandidacyRequestStatus")}
      >
        <MenuItem>{t("candidacyRequestStatuses.allCandidacyRequests")}</MenuItem>
        {candidacyRequestStatuses.map((status) => (
          <MenuItem key={status} value={status}>
            {t(`candidacyRequestStatuses.${status}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CandidacyRequestStatusesDropdown;
