import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import { ChevronLeft, Close } from '@material-ui/icons';

import OrderCandidaciesDetails from './components/OrderCandidaciesDetails';
import OrderCandidaciesList from './components/OrderCandidaciesList';
import SpinnerBackdrop from "../../../../../components/SpinnerBackdrop";
import { useFetch } from "../../../../../customHooks/useFetch";
import { getUserStatistic } from "../../../../../api/Users";

const useStyles = makeStyles((theme) => ({
  title: {
    lineHeight: `${theme.spacing(6)}px`,
    '& .MuiTypography-root': {
      lineHeight: 'inherit',
    },
  },
  back: {
    marginLeft: -theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  close: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(1),
  },
}));

const OrderCandidaciesRequestsDialog = (props) => {
  const { open, onClose, loading, candidacies, onGetFileUrl, selectedWriterId, onAcceptCandidacy } = props;
  const [selectedCandidacy] = candidacies?.filter(({ writerId }) => writerId === selectedWriterId) || [];
  const [selectedProposal, setSelectedProposal] = useState(selectedCandidacy);
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, refetch: refetchStatistic } = useFetch(getUserStatistic, {
    variables: selectedProposal?.writerId,
    skip: !selectedProposal,
  });
  const [userStatistic] = data || [];
  const handleClearSelectedProposal = () => {
    !selectedCandidacy && setSelectedProposal();
    refetchStatistic();
  };
  const handleSelectProposal = (candidacy) => {
    setSelectedProposal(candidacy);
    refetchStatistic();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={onClose}
      onExit={handleClearSelectedProposal}
    >
      <SpinnerBackdrop open={loading} />
      <DialogTitle className={classes.title}>
        {selectedProposal && (
          <>
            <IconButton disabled={!!selectedWriterId} className={classes.back} onClick={handleClearSelectedProposal}>
              <ChevronLeft />
            </IconButton>
            {selectedProposal?.user?.username}
          </>
        )}

        {!selectedProposal && t('candidacyProposal.title')}

        <IconButton className={classes.close} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {!selectedProposal && (
          <OrderCandidaciesList candidacies={candidacies} onSelectProposal={handleSelectProposal} />
        )}
        {selectedProposal && (
          <OrderCandidaciesDetails
            {...selectedProposal}
            onGetFileUrl={onGetFileUrl}
            onAcceptCandidacy={onAcceptCandidacy}
            disabled={!!selectedWriterId}
            userStatistic={userStatistic}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OrderCandidaciesRequestsDialog;
