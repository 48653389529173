import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core';

const WritersStatistic = ({ statistic }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        {t('writerStatistic.title')}
      </Typography>

      <Divider />

      <List disablePadding>
        <ListItem disableGutters dense>
          <ListItemText
            primary={
              <>
                <Typography variant="subtitle2" component="span" color="textSecondary">
                  {t('writerStatistic.totalCount')}
                </Typography>{' '}
                {statistic?.totalCandidacyRequests || 0}
              </>
            }
          />
        </ListItem>
        <ListItem disableGutters dense>
          <ListItemText
            primary={
              <>
                <Typography variant="subtitle2" component="span" color="textSecondary">
                  {t('writerStatistic.totalFinishedOrders')}
                </Typography>{' '}
                {statistic?.totalFinishedOrders || 0}
              </>
            }
          />
        </ListItem>
      </List>
    </>
  );
};
export default WritersStatistic;
