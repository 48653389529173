import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { LabelOutlined, PersonOutlined, SettingsOutlined, ExitToAppOutlined } from '@material-ui/icons';
import { logout, getUserId } from '../../services/authService';
import { useFetch } from '../../customHooks/useFetch';
import { userCan } from '../../services/ACL';
import { getUserById } from '../../api/Users';
import { beige2Color, secondaryColor } from "../../theme";

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
    [theme.breakpoints.up('md')]: {
      width: 250,
    },
    [theme.breakpoints.up('xl')]: {
      width: 350,
    },
  },
  list: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  listItem: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 30,
    },
    '&.Mui-selected': {
      backgroundColor: beige2Color,
      '&:hover': {
        backgroundColor: beige2Color,
      },
    },
  },
  accountItem: {
    height: 145,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 30,
    },
  },
  avatar: {
    backgroundColor: secondaryColor,
  },
  userName: {
    wordWrap: 'break-word',
  },
}));

const Aside = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const userId = getUserId();
  const { data: user } = useFetch(getUserById, { variables: userId } );
  const navigation = [
    {
      title: t('navigation.acceptedOrders'),
      to: '/accepted-orders',
      permission: 'see-accepted-orders-page',
      icon: <LabelOutlined /> // TODO: add another icon
    },
    {
      title: t('navigation.orders'),
      to: '/orders',
      permission: 'see-orders-page',
      icon: <LabelOutlined />
    },
    {
      title: t('navigation.candidacyRequests'),
      to: '/candidacy-requests',
      permission: 'see-candidacy-requests-page',
      icon: <PersonOutlined /> // TODO: add another icon
    },
    {
      title: t('navigation.users'),
      to: '/users',
      permission: 'see-user-page',
      icon: <PersonOutlined />
    },
    {
      title: t('navigation.settings'),
      to: '/settings',
      permission: 'see-settings-page',
      icon: <SettingsOutlined />
    },
  ];

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawer,
      }}
    >
      <Box mt={8} />
        <List className={classes.list} component="nav">
          <ListItem className={classes.accountItem}>
            <ListItemIcon>
              <Avatar variant="rounded" className={classes.avatar} />
            </ListItemIcon>
            <ListItemText primary={user?.username} className={classes.userName} />
          </ListItem>
          <Divider />

          {navigation.map(
            ({ title, to, permission, icon }, i) =>
              userCan(permission) && (
                <ListItem
                  button
                  to={to}
                  selected={history.location.pathname === to}
                  component={Link}
                  key={to}
                  className={classes.listItem}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText color='primary'>{title}</ListItemText>
                </ListItem>
              )
          )}

          <Box mt="auto" />

          <ListItem button onClick={logout} className={classes.listItem}>
            <ListItemIcon>
              <ExitToAppOutlined />
            </ListItemIcon>
            <ListItemText>
              {t('common.logout')}
            </ListItemText>
          </ListItem>

          <ListItem
            component={Typography}
            variant="caption"
            className={classes.listItem}
            style={{ paddingTop: 0, paddingBottom: 30, marginTop: -15 }}
          >
            {t('common.poweredBy')}
          </ListItem>
        </List>
    </Drawer>
  );
};

export default Aside;
