import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { primaryColor, beige2Color } from '../../../../theme.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
    display: 'block',
    marginRight: 'auto',
    '&.current': {
      marginLeft: 'auto',
      marginRight: 0,
    },
  },
  message: {
    color: primaryColor,
    marginBottom: theme.spacing(2),
    backgroundColor: beige2Color,
    borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    '&.current': {
      color: 'white',
      backgroundColor: primaryColor,
      borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
    },
    '& .MuiTypography-root': {
      whiteSpace: 'pre-wrap',
    },
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const Comment = ({ user, created_at, current, message, alignItems }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <ListItem
      className={`${classes.root} ${current && 'current'}`}
      alignItems={alignItems || 'flex-start'}
      disableGutters
    >
      <ListItemText
        primary={
          <Grid container spacing={1} wrap="nowrap">
            {user && (
              <Grid item xs>
                <Typography variant="subtitle1" component="span">
                  {user?.username || t(`comment.${user?.role}`)}
                </Typography>
              </Grid>
            )}
            {created_at && (
              <Grid item xs="auto">
                <Typography variant="caption" component="span" color="textSecondary">
                  {format(new Date(created_at), 'HH:mm, dd MMM yyyy')}
                </Typography>
              </Grid>
            )}
          </Grid>
        }
        secondary={
          <Box className={`${classes.message} ${current && 'current'}`} p={2}>
            <Typography variant="body2">{message}</Typography>
          </Box>
        }
      />
    </ListItem>
  );
};

export default Comment;
