import jsonwebtoken from 'jsonwebtoken';
import history from '../../history';
import {USER_ROLE_ADMIN, USER_ROLE_WRITER, USER_ROLE_CUSTOMER} from "../../enums/User";

const {decode} = jsonwebtoken;

export const getAccessToken = () => {
  const token = localStorage.getItem('accessToken');
  return token || null;
};

export const storeAccessToken = (token) => {
  localStorage.setItem('accessToken', token);
};

export const clearAccessToken = () => {
  localStorage.removeItem('accessToken');
};

export const clearPrevPath = () => {
  localStorage.removeItem('prevPathState');
};

export const getRole = () => {
  const accessToken = getAccessToken();
  const { role } = decode(accessToken);
  return role;
};

export const isAdmin = () => getRole() === USER_ROLE_ADMIN;
export const isWriter = () => getRole() === USER_ROLE_WRITER;
export const isCustomer = () => getRole() === USER_ROLE_CUSTOMER;

export const getUserId = () => {
  const accessToken = getAccessToken();
  const { id } = decode(accessToken);
  return id;
};

export const isLoggedIn = () => !!getAccessToken();

export const logout = () => {
  clearAccessToken();
  clearPrevPath();
  history.push('/');
};
