import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  TextField,
  Button,
  Radio,
  Grid,
  Box,
  Typography, InputAdornment, IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import SpinnerBackdrop from "../../../../../components/SpinnerBackdrop";

const EditUserModal = (props) => {
  const { open, onClose, user, onUserUpdate, isLoading } = props;
  const { t } = useTranslation();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const handleClickShowPassword = () => setIsPasswordShown(!isPasswordShown);

  const defaultValues = user;
  const { register, errors, handleSubmit } = useForm({ defaultValues });
  const handleFormSubmit = async (data) => {
    const { password, email } = data;
    await onUserUpdate({
      ...data,
      password: password.trim(),
      email: email.trim(),
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <SpinnerBackdrop open={isLoading} />
      <Box p={3} width={500}>
        <DialogTitle>
          {t('userModal.editUser')}
        </DialogTitle>
        <DialogContent>
          <form id="user-form" onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>{t('userModal.email')}</Typography>
                <TextField
                  fullWidth
                  name="email"
                  error={errors?.email}
                  helperText={errors?.email?.message}
                  placeholder={t("userModal.email")}
                  inputRef={register({
                    required: t("validationErrors.formRequired"),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: t("validationErrors.invalidEmailFormat")
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t('userModal.username')}</Typography>
                <TextField
                  fullWidth
                  name="username"
                  error={errors?.username}
                  helperText={errors?.username?.message}
                  placeholder={t("userModal.username")}
                  inputRef={register({
                    required: t("validationErrors.formRequired"),
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">{t('userModal.password')}</Typography>
                <TextField
                  fullWidth
                  type={isPasswordShown ? 'text' : 'password'}
                  name="password"
                  error={errors?.password}
                  helperText={errors?.password?.message}
                  placeholder={t("userModal.password")}
                  inputRef={register({
                    minLength: {
                      value: 5,
                      message: t('validationErrors.invalidPasswordLength')
                    }
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} style={{padding: '0px 16px'}}>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                form="user-form"
                variant="contained"
              >
                {t("userModal.save")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth onClick={onClose}>
                {t("userModal.cancel")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditUserModal;
