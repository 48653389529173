import React from 'react';

export const AppSwitch = props => {
  const { test, children } = props;
  return children.find(child => {
    return child.props.value === test;
  });
};

export const AppCase = ({ children, value }) => {
  return children;
};

  // ex
  // <AppSwitch test={true}>
  //   <AppCase value={true}>
  //     <h1>value is true</h1>
  //   </AppCase>
  //   <AppCase value={false}>
  //     <h1>value is false</h1>
  //   </AppCase>
  // </AppSwitch>
