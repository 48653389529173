import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from "react-i18next";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Box,
  Typography,
  ListItemSecondaryAction,
  List,
} from '@material-ui/core';
import { GetApp as GetAppIcon, Delete as DeleteIcon } from '@material-ui/icons';

const OrderAttachments = (props) => {
  const { getAttachmentUrl, onDeleteAttachment, ...attachments } = props;
  const { t } = useTranslation();
  const data = Object.values(attachments);

  return (
    <>
      <Typography variant="h5" component={Box} py={2}>{t('common.attachedFiles')}</Typography>
      <List>
      {data.map(
        ({comment, created_at, _id: id}) => {
          const createdAtStr = format(new Date(created_at), 'dd MMM yyyy hh:mm');
          return (<ListItem key={id}>
            <ListItemAvatar>
              <IconButton onClick={() => getAttachmentUrl(id)}>
                <GetAppIcon/>
              </IconButton>
            </ListItemAvatar>
            <ListItemText primary={comment} secondary={createdAtStr}/>
            <ListItemSecondaryAction>
              <IconButton onClick={() => onDeleteAttachment(id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>)
        }
      )}
      </List>
    </>
  );
};

export default OrderAttachments;
