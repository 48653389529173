import instance from "../../services/axiosInstance";

export const addOrderAttachment = async (id, orderAttachment) => {
  return instance.post(`orders/${id}/addAttachment`, orderAttachment);
};

export const getOrderAttachmentsData = async (id) => {
  return instance.get(`/orders/${id}/attachments`);
};

export const getOrderAttachment = async (orderAttachmentId) => {
  return instance.get(`/orders/${orderAttachmentId}/attachment`);
};

export const deleteOrderAtachment = async (orderAttachmentId) => {
  return instance.delete(`/orders/${orderAttachmentId}/attachment`);
};
