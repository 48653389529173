import React from 'react';
import { AppCase, AppSwitch } from "../../../components/AppSwitchCase";
import { getRole } from "../../../services/authService";
import { USER_ROLE_ADMIN, USER_ROLE_WRITER } from "../../../enums/User";
import AdminCandidacyRequests from "../../admin/CandidacyRequests";
import WriterCandidacyRequests from "../../writer/CandidacyRequests";

const CandidacyRequests = () => {
  return (
    <AppSwitch test={getRole()}>
      <AppCase value={USER_ROLE_ADMIN}>
        <AdminCandidacyRequests/>
      </AppCase>
      <AppCase value={USER_ROLE_WRITER}>
        <WriterCandidacyRequests />
      </AppCase>
    </AppSwitch>
  )
};

export default CandidacyRequests;
