import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Delete as DeleteIcon, Euro as EuroIcon, GetApp as GetAppIcon } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';
import { sortBy } from "lodash";

import {
  ORDER_STATUSES_FOR_ADMIN,
  ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT,
  ORDER_QUOTES_OPTIONS,
  ORDER_QUOTE_ANOTHER_STYLE,
  ORDER_DELIVERABLES_PAGES, ORDER_DELIVERABLES_OPTIONS
} from '../../../../../enums/Order';
import UploadFileField from '../../../../../components/UploadFileField';
import OrderFormField from './components/OrderFormField';
import OrderFormSelect from './components/OrderFormSelect';
import OrderFormButton from './components/OrderFormButton';
import OrderFormDatePicker from './components/OrderFormDatePicker';

const OrderForm = (props) => {
  const { values, customers = [], disabled, hasOrder, onEdit, onSubmit, onDownloadInvoice, onDeleteInvoice, isDeleteInvoice } = props;
  const { handleSubmit, register, control, errors } = useForm();
  const { t } = useTranslation();

  const minDate = new Date();
  const deadlineValue = values?.deadline ? new Date(values?.deadline) : null;

  const customersOptions = customers.map((item) => ({ ...item, name: item?.username }));
  const sortedCustomerOptions = sortBy(customersOptions, [(customer) => customer.name.toLowerCase()]);

  const statusOptions = ORDER_STATUSES_FOR_ADMIN.map((name) => ({ _id: name, name: t(`orderStatuses.${name}`) }));
  const quotesOptions = ORDER_QUOTES_OPTIONS.map((name) => ({ _id: name, name: t(`orderQuoteOptions.${name}`) }));
  const deliverableTypeOptions = ORDER_DELIVERABLES_OPTIONS.map((name) => ({ _id: name, name: t(`orderDeliverableTypeOptions.${name}`) }));

  return (
    <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <OrderFormField
            name="title"
            disabled={disabled}
            error={errors?.title}
            label={t('form.title')}
            defaultValue={values?.title}
            inputRef={register({ required: t('validationErrors.formRequired') })}
          />
        </Grid>
        <Grid item xs={3}>
          <OrderFormSelect
            name="quote"
            control={control}
            disabled={disabled}
            error={errors?.quote}
            options={quotesOptions}
            label={t('form.quote')}
            defaultValue={values?.quote || ORDER_QUOTE_ANOTHER_STYLE}
            rules={{ required: t('validationErrors.formRequired') }}
          />
        </Grid>
        <Grid item xs={12}>
          <OrderFormField
            multiline
            name="description"
            disabled={disabled}
            error={errors?.description}
            label={t('form.description')}
            defaultValue={values?.description}
            inputRef={register({ required: t('validationErrors.formRequired') })}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OrderFormSelect
            name="customerId"
            control={control}
            disabled={hasOrder}
            error={errors?.customerId}
            options={sortedCustomerOptions}
            label={t('form.customerId')}
            defaultValue={values?.customerId || ''}
            rules={{ required: t('validationErrors.formRequired') }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <OrderFormField
            name="subjectModule"
            control={control}
            disabled={disabled}
            label={t('form.subjectModule')}
            defaultValue={values?.subjectModule || ''}
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <OrderFormSelect
            name="status"
            control={control}
            disabled={!values?.status || disabled}
            error={errors?.status}
            options={statusOptions}
            label={t('form.status')}
            defaultValue={values?.status || ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT}
            rules={{ required: t('validationErrors.formRequired') }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <OrderFormDatePicker
            name="deadline"
            control={control}
            disabled={disabled}
            minDate={!disabled && minDate}
            error={errors?.deadline}
            label={t('form.deadline')}
            defaultValue={deadlineValue}
            rules={{ required: t('validationErrors.formRequired') }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OrderFormField
            name="category"
            disabled={disabled}
            error={errors?.category}
            label={t('form.category')}
            defaultValue={values?.category}
            inputRef={register({ required: t('validationErrors.formRequired') })}
          />
        </Grid>
        <Grid item xs={12} sm={3}>

          <OrderFormSelect
            name="deliverableType"
            control={control}
            disabled={disabled}
            error={errors?.deliverableType}
            options={deliverableTypeOptions}
            label={t('form.deliverableType')}
            defaultValue={values?.deliverableType || ORDER_DELIVERABLES_PAGES}
            rules={{ required: t('validationErrors.formRequired') }}
          />

        </Grid>
        <Grid item xs={12} sm={3}>
          <OrderFormField
            type="number"
            name="numberOfDeliverables"
            disabled={disabled}
            error={errors?.numberOfDeliverables}
            label={t('form.numberOfDeliverables')}
            defaultValue={values?.numberOfDeliverables}
            inputRef={register({ required: t('validationErrors.formRequired') })}
          />
        </Grid>
        <Grid item xs={12}>
          <OrderFormField
            name="customerProgressUrl"
            disabled={disabled}
            error={errors?.customerProgressUrl}
            label={t('form.customerProgressUrl')}
            defaultValue={values?.customerProgressUrl}
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12}>
          <OrderFormField
            name="writerProgressUrl"
            disabled={disabled}
            error={errors?.writerProgressUrl}
            label={t('form.writerProgressUrl')}
            defaultValue={values?.writerProgressUrl}
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <OrderFormField
                type="number"
                name="price"
                disabled={disabled}
                error={errors?.price}
                label={t('form.price')}
                defaultValue={values?.price}
                InputProps={{ min: 10, endAdornment: <EuroIcon fontSize="small" /> }}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6}>
              <OrderFormField
                type="number"
                name="compensation"
                disabled={disabled}
                error={errors?.compensation}
                label={t('form.compensation')}
                defaultValue={values?.compensation}
                InputProps={{ min: 10, endAdornment: <EuroIcon fontSize="small" /> }}
                inputRef={register}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <OrderFormField
            name="school"
            disabled={disabled}
            error={errors?.school}
            label={t('form.school')}
            defaultValue={values?.school}
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={1}>
            {values?.invoiceUrl && (
              <Grid item xs="auto">
                <OrderFormButton variant="text" disabled={isDeleteInvoice} onClick={onDownloadInvoice}>
                  {t('orders.downloadInvoice')}
                  <GetAppIcon />
                </OrderFormButton>
              </Grid>
            )}

            {values?.invoiceUrl && !disabled && (
              <Grid item xs="auto" m={0}>
                <IconButton onClick={onDeleteInvoice}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}

            {!disabled && (
              <Grid item xs>
                <UploadFileField
                  name="invoiceUrl"
                  disabled={disabled}
                  inputRef={register}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <OrderFormButton type="submit" disabled={disabled} color="primary">
                {t('form.submit')}
              </OrderFormButton>
            </Grid>
            {hasOrder && (
              <Grid item xs={6}>
                <OrderFormButton onClick={onEdit} color="secondary">
                  {!disabled ? t('form.cancel') : t('form.edit')}
                </OrderFormButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default OrderForm;
