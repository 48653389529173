import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Container,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { login } from "../../../api/Login";
import { clearPrevPath, isLoggedIn, storeAccessToken } from "../../../services/authService";
import {beige7Color} from "../../../theme";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
  },
  logo: {
    marginBottom: theme.spacing(3),
  },
  imageContainer: {
    backgroundColor: '#F8F1ED',
    [theme.breakpoints.down('sm')]: {
      padding: 40,
    },
    [theme.breakpoints.up('md')]: {
      padding: '60px 80px',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '100px 120px 50px',
    },
  },
  title: {
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
      lineHeight: '40px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
      lineHeight: '50px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 70,
      lineHeight: '80px',
    },
  },
  backgroundImage: {
    marginTop: 'auto',
    '& img': {
      width: '100%',
      maxWidth: '420px',
    },
  },
  formContainer: {
    alignSelf: 'center',
  },
  form: {
    maxWidth: 420,
    textAlign: 'center',
    margin: 'auto',
  },
  signInText: {
    marginBottom: 40,
    fontSize: 40,
  },
  signInButton: {
    fontSize: 16,
    textTransform: 'initial',
  },
  linkStyle: {
    color: beige7Color,
    textDecoration: 'none',
  },
}));

const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const history = useHistory();
  const adminEmail = process.env.REACT_APP_SUPPORT_EMAIL || 'info@paper-black.de';

  if (isLoggedIn()) {
    history.push('/orders');
  }

  const onSubmit = async (form) => {
    const { email, password } = form;
    try {
      const { token } = await login({
        email: email.trim(),
        password: password.trim(),
      });
      storeAccessToken(token);
      const prevPath = localStorage.getItem('prevPathState') || '/orders';
      history.push(prevPath);
      clearPrevPath();
    } catch ({ response }) {
      toast.error(response.data);
    }
  };

  const handleClickShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  return (
    <Grid container className={classes.root}>
      <Grid container direction="column" item xs={5} className={classes.imageContainer}>
        <Grid item>
          <img alt="" className={classes.logo} src={`${process.env.PUBLIC_URL}/paperBlackLogo.svg`} />
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="h1" className={classes.title}>
            {t('login.title')}
          </Typography>
        </Grid>
        <Grid item className={classes.backgroundImage}>
          <img alt="" src={`${process.env.PUBLIC_URL}/backGroundLogo.svg`} />
        </Grid>
      </Grid>
      <Grid item xs={7} className={classes.formContainer}>
        <Container className={classes.form}>
          <Typography variant="h2" className={classes.signInText}>
            {t('login.signIn')}
          </Typography>
          <Box mt={1} />
          <form id="login" onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs>
              <TextField
                variant="outlined"
                fullWidth
                placeholder={t('login.email')}
                name="email"
                error={!!errors.email}
                helperText={errors?.email?.message}
                inputRef={register({
                  required: t('validationErrors.formRequired'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: t('validationErrors.invalidEmailFormat'),
                  },
                })}
                autoFocus
              />
            </Grid>
            <Box mt={2} />
            <Grid item xs>
              <TextField
                variant="outlined"
                fullWidth
                placeholder={t('login.password')}
                name="password"
                error={!!errors?.password}
                helperText={errors?.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={register({ required: t('validationErrors.formRequired') })}
                type={isPasswordShown ? 'text' : 'password'}
              />
            </Grid>
          </form>
          <Box mt={1} />
          <Grid item xs container justify="flex-end">
            <Typography variant="body2">
              <a
                className={classes.linkStyle}
                href={`mailto:${adminEmail}?subject=${t('login.emails.forgotPassword.subject')}&body=${t('login.emails.forgotPassword.body')}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('login.forgotPassword')}
              </a>
            </Typography>
          </Grid>
          {/* <Box mt={3} /> */}
          {/* <Grid item xs> */}
          {/*  <FormControlLabel */}
          {/*    control={ */}
          {/*      <Checkbox */}
          {/*        color="primary" */}
          {/*        checked={isRememberMeChecked} */}
          {/*        onChange={({ target }) => setIsRememberMeChecked(target.checked)} */}
          {/*      />} */}
          {/*    label={t('login.rememberPassword')} */}
          {/*  /> */}
          {/* </Grid> */}
          <Box mt={4} />
          <Grid item>
            <Button
              type="submit"
              form="login"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.signInButton}
            >
              {t('login.login')}
            </Button>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
