import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SpinnerBackdrop from "../../../../../components/SpinnerBackdrop";
import UploadFileField from '../../../../../components/UploadFileField';

const useStyles = makeStyles((theme) => ({
  close: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  content: {
    overflowY: "initial",
  },
}));

const AddOrderAttachment = (props) => {
  const { open, onClose, onSubmit, isLoading } = props;
  const { handleSubmit, register, errors } = useForm();
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <SpinnerBackdrop open={isLoading} />
      <DialogTitle>
        {t('orders.addAttachment')}
        <IconButton className={classes.close} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <form id="order-attachment" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                rows={6}
                multiline
                name="comment"
                variant="outlined"
                label={t('form.comment')}
                error={!!errors?.comment}
                helperText={errors?.comment?.message}
                inputRef={register({ required: t('validationErrors.formRequired') })}
              />
            </Grid>
            <Grid item xs={12}>
              <UploadFileField
                fullWidth
                name="file"
                error={!!errors?.file}
                helperText={errors?.file?.message}
                inputRef={register({ required: t('validationErrors.formRequired') })}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="contained" color="primary" form="order-attachment">
          {t('form.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrderAttachment;
