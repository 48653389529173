import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Box,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";

const InformationDialog = (props) => {
  const { t } = useTranslation();
  const { title, open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={3} width={500}>
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            {t('common.acknowledged')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
};

export default InformationDialog;
