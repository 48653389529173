import instance from '../../services/axiosInstance';

export const getOrders = async (writerId) => {
  return instance.get('orders',{ params: { writerId } });
};

export const getOrderById = async (id) => {
  return instance.get(`orders/${id}`);
};

export const getOrderInvoice = async (orderId) => {
  return instance.get(`/orders/${orderId}/invoice`);
};

export const createOrder = async (createOrderInfo) => {
  return instance.post('orders', createOrderInfo);
};

export const updateOrderById = async (id, updateOrderInfo) => {
  return instance.put(`orders/${id}`, updateOrderInfo);
};

export const approveOrderById = async (id, updateOrderInfo) => {
  return instance.put(`orders/${id}/approve-order`, updateOrderInfo);
};

export const submitWriterOrderReview = async (id, updateOrderInfo) => {
  return instance.put(`orders/${id}/submit-review`, updateOrderInfo);
};

export const deleteOrderById = async (id) => {
  return instance.delete(`orders/${id}`);
};

export const acceptOrder = async (id, updateOrderInfo) => {
  return instance.put(`orders/${id}/acceptOrder`, updateOrderInfo);
};
