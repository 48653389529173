import axios from 'axios';

import {
  tokenInterceptor,
  unauthorisedResponse,
  dataExtractionInterceptor,
} from '../interceptors';

const instance = axios.create({
  baseURL:  process.env.REACT_APP_API_URL || 'http://localhost:3000/api/',
});

instance.interceptors.request.use(tokenInterceptor);
instance.interceptors.response.use(res => res, unauthorisedResponse);
instance.interceptors.response.use(dataExtractionInterceptor);

export default instance;
