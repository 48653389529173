import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Typography, makeStyles, ButtonGroup } from '@material-ui/core';
import { beige7Color, primaryColor } from '../../../../../theme';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  group: {
    '& .MuiButton-label': {
      fontFamily: 'Source Sans Pro, sans-serif',
      fontWeight: 400,
      textTransform: 'none',
      fontSize: '15px',

    },
    backgroundColor: 'transparent',
    '& .active': {
      color: primaryColor,
    }
  },
  title: {
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
  },
  option: {
    color: beige7Color,
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: 600,
    fontSize: '16px',
  }
}));

const CalculatorPaperType = ({ value, options, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={6} sm={3} container justify="flex-end">
        <Typography className={classes.title} variant="body1" color="primary">
          {t('calculator.paperType')}
        </Typography>
      </Grid>
      <Hidden smUp>
        <Grid item xs={6} container justify="flex-start">
          <Typography variant="subtitle2" className={classes.option}>
            {options[value || 0]?.label}
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={7}>
        <ButtonGroup className={classes.group} color="secondary" disableElevation fullWidth>
          {options?.map((item, i) => (
            <Button className={{ active: value === i }} size='small' onClick={() => onChange(i)} key={i}>
              {item.label}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Hidden xsDown>
        <Grid item xs={2}>
          <Typography variant="subtitle2" className={classes.option}>
            {options[value || 0]?.label}
          </Typography>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default CalculatorPaperType;
