import React from 'react';
import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import Decimal from 'decimal.js';

import { ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT } from '../../../../enums/Order';

export const ManualPayment = ( props ) => {
  const { order } = props;
  const { price, status } = order;
  const { t } = useTranslation();

  const percentageToPay = (orderStatus) => (
    [ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT].includes(orderStatus) ? 0.2 : 0.8
  );

  const decimalPrice = new Decimal(Number(price));
  const decimalPercentages = new Decimal(percentageToPay(status));
  const priceToPay = decimalPrice.times(decimalPercentages).toDecimalPlaces(2, Decimal.ROUND_CEIL).toNumber();

  return (
    <Paper>
      <Box p={4}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {t('orderCheckout.priceToPay')}
        </Typography>
        <Typography variant="body1">
          {priceToPay} EUR
        </Typography>

        <Box pb={4}/>

        <Typography variant='subtitle1'>
          {t('orderCheckout.manualPayment.title')}
        </Typography>
        {[ORDER_STATUS_WAITING_FOR_DEPOSIT_PAYMENT].includes(status) && <Typography variant='subtitle1'>
          {t('orderCheckout.manualPayment.depositMessage')}
        </Typography>}
        <Box pb={2}/>

        <Typography variant='body2'>
          Paper Black GmbH
        </Typography>
        <Box pb={2}/>

        <Typography variant='body2'>
          IBAN: DE28 3704 0048 0812 9777 00
        </Typography>
        <Box pb={2}/>

        <Typography variant='body2'>
          BIC: COBADEFFXXX
        </Typography>
        <Box pb={2}/>

        <Typography variant='body2'>
          Commerzbank Köln
        </Typography>
        <Box pb={2}/>
      </Box>
    </Paper>
  )
};
